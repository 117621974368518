"use strict";// source: WiModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoExchange',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiPriceStream',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscription',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionAddData',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionFee',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionUpdateData',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiPriceStream=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoWiPriceStream.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoWiPriceStream,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiPriceStream.displayName='proto.SBProtoMessages.ProtoWiPriceStream';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscription=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscription,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscription.displayName='proto.SBProtoMessages.ProtoWiSubscription';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionFee=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionFee,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionFee.displayName='proto.SBProtoMessages.ProtoWiSubscriptionFee';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoExchange=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoExchange,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoExchange.displayName='proto.SBProtoMessages.ProtoExchange';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionAddData,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionAddData.displayName='proto.SBProtoMessages.ProtoWiSubscriptionAddData';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionUpdateData,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.displayName='proto.SBProtoMessages.ProtoWiSubscriptionUpdateData';}/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoWiPriceStream.repeatedFields_=[7];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiPriceStream.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiPriceStream.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiPriceStream} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiPriceStream.toObject=function(includeInstance,msg){var f,obj={pricestreamid:(f=jspb.Message.getField(msg,1))==null?undefined:f,name:(f=jspb.Message.getField(msg,2))==null?undefined:f,miccode:(f=jspb.Message.getField(msg,3))==null?undefined:f,country:(f=jspb.Message.getField(msg,4))==null?undefined:f,feecents:(f=jspb.Message.getField(msg,5))==null?undefined:f,feecurrency:(f=jspb.Message.getField(msg,6))==null?undefined:f,miccodesList:(f=jspb.Message.getRepeatedField(msg,7))==null?undefined:f,disabled:jspb.Message.getBooleanFieldWithDefault(msg,8,false)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream}
 */proto.SBProtoMessages.ProtoWiPriceStream.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiPriceStream();return proto.SBProtoMessages.ProtoWiPriceStream.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiPriceStream} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream}
 */proto.SBProtoMessages.ProtoWiPriceStream.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint64();msg.setPricestreamid(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setName(value);break;case 3:var value=/** @type {string} */reader.readString();msg.setMiccode(value);break;case 4:var value=/** @type {string} */reader.readString();msg.setCountry(value);break;case 5:var value=/** @type {number} */reader.readUint64();msg.setFeecents(value);break;case 6:var value=/** @type {string} */reader.readString();msg.setFeecurrency(value);break;case 7:var value=/** @type {string} */reader.readString();msg.addMiccodes(value);break;case 8:var value=/** @type {boolean} */reader.readBool();msg.setDisabled(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiPriceStream.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiPriceStream} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiPriceStream.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint64(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}f=/** @type {string} */jspb.Message.getField(message,4);if(f!=null){writer.writeString(4,f);}f=/** @type {number} */jspb.Message.getField(message,5);if(f!=null){writer.writeUint64(5,f);}f=/** @type {string} */jspb.Message.getField(message,6);if(f!=null){writer.writeString(6,f);}f=message.getMiccodesList();if(f.length>0){writer.writeRepeatedString(7,f);}f=/** @type {boolean} */jspb.Message.getField(message,8);if(f!=null){writer.writeBool(8,f);}};/**
 * required uint64 priceStreamId = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getPricestreamid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setPricestreamid=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearPricestreamid=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasPricestreamid=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required string name = 2;
 * @return {string}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getName=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setName=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearName=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasName=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional string micCode = 3;
 * @return {string}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getMiccode=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setMiccode=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearMiccode=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasMiccode=function(){return jspb.Message.getField(this,3)!=null;};/**
 * required string country = 4;
 * @return {string}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getCountry=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,4,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setCountry=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearCountry=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasCountry=function(){return jspb.Message.getField(this,4)!=null;};/**
 * required uint64 feeCents = 5;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getFeecents=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,5,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setFeecents=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearFeecents=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasFeecents=function(){return jspb.Message.getField(this,5)!=null;};/**
 * required string feeCurrency = 6;
 * @return {string}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getFeecurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,6,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setFeecurrency=function(value){return jspb.Message.setField(this,6,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearFeecurrency=function(){return jspb.Message.setField(this,6,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasFeecurrency=function(){return jspb.Message.getField(this,6)!=null;};/**
 * repeated string micCodes = 7;
 * @return {!Array<string>}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getMiccodesList=function(){return(/** @type {!Array<string>} */jspb.Message.getRepeatedField(this,7));};/**
 * @param {!Array<string>} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setMiccodesList=function(value){return jspb.Message.setField(this,7,value||[]);};/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.addMiccodes=function(value,opt_index){return jspb.Message.addToRepeatedField(this,7,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearMiccodesList=function(){return this.setMiccodesList([]);};/**
 * optional bool disabled = 8;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.getDisabled=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,8,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.setDisabled=function(value){return jspb.Message.setField(this,8,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream} returns this
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.clearDisabled=function(){return jspb.Message.setField(this,8,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiPriceStream.prototype.hasDisabled=function(){return jspb.Message.getField(this,8)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscription.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscription.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscription} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscription.toObject=function(includeInstance,msg){var f,obj={subscriptionid:(f=jspb.Message.getField(msg,1))==null?undefined:f,pricestreamid:(f=jspb.Message.getField(msg,2))==null?undefined:f,expirationtimestamp:(f=jspb.Message.getField(msg,3))==null?undefined:f,autorenew:(f=jspb.Message.getBooleanField(msg,4))==null?undefined:f,historicalfee:(f=msg.getHistoricalfee())&&proto.SBProtoMessages.ProtoWiSubscriptionFee.toObject(includeInstance,f)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription}
 */proto.SBProtoMessages.ProtoWiSubscription.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscription();return proto.SBProtoMessages.ProtoWiSubscription.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscription} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription}
 */proto.SBProtoMessages.ProtoWiSubscription.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint64();msg.setSubscriptionid(value);break;case 2:var value=/** @type {number} */reader.readUint64();msg.setPricestreamid(value);break;case 3:var value=/** @type {number} */reader.readUint64();msg.setExpirationtimestamp(value);break;case 4:var value=/** @type {boolean} */reader.readBool();msg.setAutorenew(value);break;case 5:var value=new proto.SBProtoMessages.ProtoWiSubscriptionFee();reader.readMessage(value,proto.SBProtoMessages.ProtoWiSubscriptionFee.deserializeBinaryFromReader);msg.setHistoricalfee(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscription.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscription} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscription.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint64(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeUint64(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeUint64(3,f);}f=/** @type {boolean} */jspb.Message.getField(message,4);if(f!=null){writer.writeBool(4,f);}f=message.getHistoricalfee();if(f!=null){writer.writeMessage(5,f,proto.SBProtoMessages.ProtoWiSubscriptionFee.serializeBinaryToWriter);}};/**
 * required uint64 subscriptionId = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.getSubscriptionid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.setSubscriptionid=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.clearSubscriptionid=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.hasSubscriptionid=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required uint64 priceStreamId = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.getPricestreamid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.setPricestreamid=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.clearPricestreamid=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.hasPricestreamid=function(){return jspb.Message.getField(this,2)!=null;};/**
 * required uint64 expirationTimestamp = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.getExpirationtimestamp=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,3,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.setExpirationtimestamp=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.clearExpirationtimestamp=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.hasExpirationtimestamp=function(){return jspb.Message.getField(this,3)!=null;};/**
 * required bool autoRenew = 4;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.getAutorenew=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,4,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.setAutorenew=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.clearAutorenew=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.hasAutorenew=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional ProtoWiSubscriptionFee historicalFee = 5;
 * @return {?proto.SBProtoMessages.ProtoWiSubscriptionFee}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.getHistoricalfee=function(){return(/** @type{?proto.SBProtoMessages.ProtoWiSubscriptionFee} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.ProtoWiSubscriptionFee,5));};/**
 * @param {?proto.SBProtoMessages.ProtoWiSubscriptionFee|undefined} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
*/proto.SBProtoMessages.ProtoWiSubscription.prototype.setHistoricalfee=function(value){return jspb.Message.setWrapperField(this,5,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscription} returns this
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.clearHistoricalfee=function(){return this.setHistoricalfee(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscription.prototype.hasHistoricalfee=function(){return jspb.Message.getField(this,5)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionFee.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionFee} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionFee.toObject=function(includeInstance,msg){var f,obj={feecents:(f=jspb.Message.getField(msg,1))==null?undefined:f,currency:(f=jspb.Message.getField(msg,2))==null?undefined:f,dealid:(f=jspb.Message.getField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionFee();return proto.SBProtoMessages.ProtoWiSubscriptionFee.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionFee} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint64();msg.setFeecents(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setCurrency(value);break;case 3:var value=/** @type {number} */reader.readUint64();msg.setDealid(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionFee.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionFee} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint64(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeUint64(3,f);}};/**
 * required uint64 feeCents = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.getFeecents=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.setFeecents=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.clearFeecents=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.hasFeecents=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required string currency = 2;
 * @return {string}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.getCurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.setCurrency=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.clearCurrency=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.hasCurrency=function(){return jspb.Message.getField(this,2)!=null;};/**
 * required uint64 dealId = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.getDealid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,3,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.setDealid=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionFee} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.clearDealid=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionFee.prototype.hasDealid=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoExchange.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoExchange.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoExchange} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoExchange.toObject=function(includeInstance,msg){var f,obj={miccode:(f=jspb.Message.getField(msg,1))==null?undefined:f,name:(f=jspb.Message.getField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoExchange}
 */proto.SBProtoMessages.ProtoExchange.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoExchange();return proto.SBProtoMessages.ProtoExchange.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoExchange} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoExchange}
 */proto.SBProtoMessages.ProtoExchange.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {string} */reader.readString();msg.setMiccode(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setName(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoExchange.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoExchange.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoExchange} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoExchange.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {string} */jspb.Message.getField(message,1);if(f!=null){writer.writeString(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}};/**
 * optional string micCode = 1;
 * @return {string}
 */proto.SBProtoMessages.ProtoExchange.prototype.getMiccode=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,1,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoExchange} returns this
 */proto.SBProtoMessages.ProtoExchange.prototype.setMiccode=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoExchange} returns this
 */proto.SBProtoMessages.ProtoExchange.prototype.clearMiccode=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoExchange.prototype.hasMiccode=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional string name = 2;
 * @return {string}
 */proto.SBProtoMessages.ProtoExchange.prototype.getName=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoExchange} returns this
 */proto.SBProtoMessages.ProtoExchange.prototype.setName=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoExchange} returns this
 */proto.SBProtoMessages.ProtoExchange.prototype.clearName=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoExchange.prototype.hasName=function(){return jspb.Message.getField(this,2)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionAddData.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionAddData.toObject=function(includeInstance,msg){var f,obj={pricestreamid:(f=jspb.Message.getField(msg,1))==null?undefined:f,autorenew:(f=jspb.Message.getBooleanField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionAddData();return proto.SBProtoMessages.ProtoWiSubscriptionAddData.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint64();msg.setPricestreamid(value);break;case 2:var value=/** @type {boolean} */reader.readBool();msg.setAutorenew(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionAddData.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint64(1,f);}f=/** @type {boolean} */jspb.Message.getField(message,2);if(f!=null){writer.writeBool(2,f);}};/**
 * required uint64 priceStreamId = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.getPricestreamid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.setPricestreamid=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.clearPricestreamid=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.hasPricestreamid=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required bool autoRenew = 2;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.getAutorenew=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,2,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.setAutorenew=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.clearAutorenew=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddData.prototype.hasAutorenew=function(){return jspb.Message.getField(this,2)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.toObject=function(includeInstance,msg){var f,obj={subscriptionid:(f=jspb.Message.getField(msg,1))==null?undefined:f,autorenew:(f=jspb.Message.getBooleanField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionUpdateData();return proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint64();msg.setSubscriptionid(value);break;case 2:var value=/** @type {boolean} */reader.readBool();msg.setAutorenew(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint64(1,f);}f=/** @type {boolean} */jspb.Message.getField(message,2);if(f!=null){writer.writeBool(2,f);}};/**
 * required uint64 subscriptionId = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.getSubscriptionid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.setSubscriptionid=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.clearSubscriptionid=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.hasSubscriptionid=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required bool autoRenew = 2;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.getAutorenew=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,2,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.setAutorenew=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.clearAutorenew=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionUpdateData.prototype.hasAutorenew=function(){return jspb.Message.getField(this,2)!=null;};goog.object.extend(exports,proto.SBProtoMessages);