"use strict";// source: WiProtoMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);var WiModel_pb=require('./WiModel_pb.js');goog.object.extend(proto,WiModel_pb);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionAddReq',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionAddRes',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionListEvent',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionListReq',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoWiSubscriptionListRes',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionListReq,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionListReq.displayName='proto.SBProtoMessages.ProtoWiSubscriptionListReq';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoWiSubscriptionListRes.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionListRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionListRes.displayName='proto.SBProtoMessages.ProtoWiSubscriptionListRes';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoWiSubscriptionListEvent.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionListEvent,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.displayName='proto.SBProtoMessages.ProtoWiSubscriptionListEvent';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoWiSubscriptionAddReq.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionAddReq,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.displayName='proto.SBProtoMessages.ProtoWiSubscriptionAddReq';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoWiSubscriptionAddRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.displayName='proto.SBProtoMessages.ProtoWiSubscriptionAddRes';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionListReq.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListReq} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionListReq.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1000)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListReq}
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionListReq();return proto.SBProtoMessages.ProtoWiSubscriptionListReq.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListReq}
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionListReq.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1000));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionListReq.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.repeatedFields_=[2,3,4];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionListRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionListRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1001),pricestreamsList:jspb.Message.toObjectList(msg.getPricestreamsList(),WiModel_pb.ProtoWiPriceStream.toObject,includeInstance),subscriptionsList:jspb.Message.toObjectList(msg.getSubscriptionsList(),WiModel_pb.ProtoWiSubscription.toObject,includeInstance),exchangesList:jspb.Message.toObjectList(msg.getExchangesList(),WiModel_pb.ProtoExchange.toObject,includeInstance)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionListRes();return proto.SBProtoMessages.ProtoWiSubscriptionListRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=new WiModel_pb.ProtoWiPriceStream();reader.readMessage(value,WiModel_pb.ProtoWiPriceStream.deserializeBinaryFromReader);msg.addPricestreams(value);break;case 3:var value=new WiModel_pb.ProtoWiSubscription();reader.readMessage(value,WiModel_pb.ProtoWiSubscription.deserializeBinaryFromReader);msg.addSubscriptions(value);break;case 4:var value=new WiModel_pb.ProtoExchange();reader.readMessage(value,WiModel_pb.ProtoExchange.deserializeBinaryFromReader);msg.addExchanges(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionListRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getPricestreamsList();if(f.length>0){writer.writeRepeatedMessage(2,f,WiModel_pb.ProtoWiPriceStream.serializeBinaryToWriter);}f=message.getSubscriptionsList();if(f.length>0){writer.writeRepeatedMessage(3,f,WiModel_pb.ProtoWiSubscription.serializeBinaryToWriter);}f=message.getExchangesList();if(f.length>0){writer.writeRepeatedMessage(4,f,WiModel_pb.ProtoExchange.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1001));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated ProtoWiPriceStream priceStreams = 2;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiPriceStream>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.getPricestreamsList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiPriceStream>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiPriceStream,2));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiPriceStream>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.setPricestreamsList=function(value){return jspb.Message.setRepeatedWrapperField(this,2,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiPriceStream=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.addPricestreams=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,2,opt_value,proto.SBProtoMessages.ProtoWiPriceStream,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.clearPricestreamsList=function(){return this.setPricestreamsList([]);};/**
 * repeated ProtoWiSubscription subscriptions = 3;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiSubscription>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.getSubscriptionsList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiSubscription>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiSubscription,3));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiSubscription>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.setSubscriptionsList=function(value){return jspb.Message.setRepeatedWrapperField(this,3,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiSubscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscription}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.addSubscriptions=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,3,opt_value,proto.SBProtoMessages.ProtoWiSubscription,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.clearSubscriptionsList=function(){return this.setSubscriptionsList([]);};/**
 * repeated ProtoExchange exchanges = 4;
 * @return {!Array<!proto.SBProtoMessages.ProtoExchange>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.getExchangesList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoExchange>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoExchange,4));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoExchange>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.setExchangesList=function(value){return jspb.Message.setRepeatedWrapperField(this,4,value);};/**
 * @param {!proto.SBProtoMessages.ProtoExchange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoExchange}
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.addExchanges=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,4,opt_value,proto.SBProtoMessages.ProtoExchange,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListRes.prototype.clearExchangesList=function(){return this.setExchangesList([]);};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.repeatedFields_=[2,3,4,5,6];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionListEvent.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1002),pricestreamsList:jspb.Message.toObjectList(msg.getPricestreamsList(),WiModel_pb.ProtoWiPriceStream.toObject,includeInstance),subscriptionsList:jspb.Message.toObjectList(msg.getSubscriptionsList(),WiModel_pb.ProtoWiSubscription.toObject,includeInstance),removedpricestreamidsList:(f=jspb.Message.getRepeatedField(msg,4))==null?undefined:f,removedsubscriptionsList:(f=jspb.Message.getRepeatedField(msg,5))==null?undefined:f,exchangesList:jspb.Message.toObjectList(msg.getExchangesList(),WiModel_pb.ProtoExchange.toObject,includeInstance)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionListEvent();return proto.SBProtoMessages.ProtoWiSubscriptionListEvent.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=new WiModel_pb.ProtoWiPriceStream();reader.readMessage(value,WiModel_pb.ProtoWiPriceStream.deserializeBinaryFromReader);msg.addPricestreams(value);break;case 3:var value=new WiModel_pb.ProtoWiSubscription();reader.readMessage(value,WiModel_pb.ProtoWiSubscription.deserializeBinaryFromReader);msg.addSubscriptions(value);break;case 4:var value=/** @type {number} */reader.readUint64();msg.addRemovedpricestreamids(value);break;case 5:var value=/** @type {number} */reader.readUint64();msg.addRemovedsubscriptions(value);break;case 6:var value=new WiModel_pb.ProtoExchange();reader.readMessage(value,WiModel_pb.ProtoExchange.deserializeBinaryFromReader);msg.addExchanges(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionListEvent.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getPricestreamsList();if(f.length>0){writer.writeRepeatedMessage(2,f,WiModel_pb.ProtoWiPriceStream.serializeBinaryToWriter);}f=message.getSubscriptionsList();if(f.length>0){writer.writeRepeatedMessage(3,f,WiModel_pb.ProtoWiSubscription.serializeBinaryToWriter);}f=message.getRemovedpricestreamidsList();if(f.length>0){writer.writeRepeatedUint64(4,f);}f=message.getRemovedsubscriptionsList();if(f.length>0){writer.writeRepeatedUint64(5,f);}f=message.getExchangesList();if(f.length>0){writer.writeRepeatedMessage(6,f,WiModel_pb.ProtoExchange.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1002));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated ProtoWiPriceStream priceStreams = 2;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiPriceStream>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getPricestreamsList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiPriceStream>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiPriceStream,2));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiPriceStream>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setPricestreamsList=function(value){return jspb.Message.setRepeatedWrapperField(this,2,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiPriceStream=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiPriceStream}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.addPricestreams=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,2,opt_value,proto.SBProtoMessages.ProtoWiPriceStream,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearPricestreamsList=function(){return this.setPricestreamsList([]);};/**
 * repeated ProtoWiSubscription subscriptions = 3;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiSubscription>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getSubscriptionsList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiSubscription>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiSubscription,3));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiSubscription>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setSubscriptionsList=function(value){return jspb.Message.setRepeatedWrapperField(this,3,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiSubscription=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscription}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.addSubscriptions=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,3,opt_value,proto.SBProtoMessages.ProtoWiSubscription,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearSubscriptionsList=function(){return this.setSubscriptionsList([]);};/**
 * repeated uint64 removedPriceStreamIds = 4;
 * @return {!Array<number>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getRemovedpricestreamidsList=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedField(this,4));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setRemovedpricestreamidsList=function(value){return jspb.Message.setField(this,4,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.addRemovedpricestreamids=function(value,opt_index){return jspb.Message.addToRepeatedField(this,4,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearRemovedpricestreamidsList=function(){return this.setRemovedpricestreamidsList([]);};/**
 * repeated uint64 removedSubscriptions = 5;
 * @return {!Array<number>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getRemovedsubscriptionsList=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedField(this,5));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setRemovedsubscriptionsList=function(value){return jspb.Message.setField(this,5,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.addRemovedsubscriptions=function(value,opt_index){return jspb.Message.addToRepeatedField(this,5,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearRemovedsubscriptionsList=function(){return this.setRemovedsubscriptionsList([]);};/**
 * repeated ProtoExchange exchanges = 6;
 * @return {!Array<!proto.SBProtoMessages.ProtoExchange>}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.getExchangesList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoExchange>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoExchange,6));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoExchange>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.setExchangesList=function(value){return jspb.Message.setRepeatedWrapperField(this,6,value);};/**
 * @param {!proto.SBProtoMessages.ProtoExchange=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoExchange}
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.addExchanges=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,6,opt_value,proto.SBProtoMessages.ProtoExchange,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionListEvent} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionListEvent.prototype.clearExchangesList=function(){return this.setExchangesList([]);};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.repeatedFields_=[2,4];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionAddReq.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1004),pricestreamstoaddList:jspb.Message.toObjectList(msg.getPricestreamstoaddList(),WiModel_pb.ProtoWiSubscriptionAddData.toObject,includeInstance),subscriptionstoupdateList:jspb.Message.toObjectList(msg.getSubscriptionstoupdateList(),WiModel_pb.ProtoWiSubscriptionUpdateData.toObject,includeInstance)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionAddReq();return proto.SBProtoMessages.ProtoWiSubscriptionAddReq.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=new WiModel_pb.ProtoWiSubscriptionAddData();reader.readMessage(value,WiModel_pb.ProtoWiSubscriptionAddData.deserializeBinaryFromReader);msg.addPricestreamstoadd(value);break;case 4:var value=new WiModel_pb.ProtoWiSubscriptionUpdateData();reader.readMessage(value,WiModel_pb.ProtoWiSubscriptionUpdateData.deserializeBinaryFromReader);msg.addSubscriptionstoupdate(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionAddReq.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getPricestreamstoaddList();if(f.length>0){writer.writeRepeatedMessage(2,f,WiModel_pb.ProtoWiSubscriptionAddData.serializeBinaryToWriter);}f=message.getSubscriptionstoupdateList();if(f.length>0){writer.writeRepeatedMessage(4,f,WiModel_pb.ProtoWiSubscriptionUpdateData.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1004));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated ProtoWiSubscriptionAddData priceStreamsToAdd = 2;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiSubscriptionAddData>}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.getPricestreamstoaddList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiSubscriptionAddData>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiSubscriptionAddData,2));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiSubscriptionAddData>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.setPricestreamstoaddList=function(value){return jspb.Message.setRepeatedWrapperField(this,2,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddData}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.addPricestreamstoadd=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,2,opt_value,proto.SBProtoMessages.ProtoWiSubscriptionAddData,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.clearPricestreamstoaddList=function(){return this.setPricestreamstoaddList([]);};/**
 * repeated ProtoWiSubscriptionUpdateData subscriptionsToUpdate = 4;
 * @return {!Array<!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData>}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.getSubscriptionstoupdateList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData>} */jspb.Message.getRepeatedWrapperField(this,WiModel_pb.ProtoWiSubscriptionUpdateData,4));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData>} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
*/proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.setSubscriptionstoupdateList=function(value){return jspb.Message.setRepeatedWrapperField(this,4,value);};/**
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionUpdateData}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.addSubscriptionstoupdate=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,4,opt_value,proto.SBProtoMessages.ProtoWiSubscriptionUpdateData,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddReq} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddReq.prototype.clearSubscriptionstoupdateList=function(){return this.setSubscriptionstoupdateList([]);};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoWiSubscriptionAddRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1005)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoWiSubscriptionAddRes();return proto.SBProtoMessages.ProtoWiSubscriptionAddRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoWiSubscriptionAddRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1005));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoWiSubscriptionAddRes} returns this
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoWiSubscriptionAddRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};goog.object.extend(exports,proto.SBProtoMessages);