"use strict";// source: PortfolioOptimizationMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);var PortfolioOptimizationModel_pb=require('./PortfolioOptimizationModel_pb.js');goog.object.extend(proto,PortfolioOptimizationModel_pb);goog.exportSymbol('proto.SBProtoMessages.EqualAllocationsRequest',null,global);goog.exportSymbol('proto.SBProtoMessages.EqualAllocationsResponse',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.EqualAllocationsRequest=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.EqualAllocationsRequest,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.EqualAllocationsRequest.displayName='proto.SBProtoMessages.EqualAllocationsRequest';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.EqualAllocationsResponse=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.EqualAllocationsResponse,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.EqualAllocationsResponse.displayName='proto.SBProtoMessages.EqualAllocationsResponse';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.EqualAllocationsRequest.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.EqualAllocationsRequest.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.EqualAllocationsRequest} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.EqualAllocationsRequest.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,2000),instrumentsMap:(f=msg.getInstrumentsMap())?f.toObject(includeInstance,proto.SBProtoMessages.PositionInfo.toObject):[],funds:(f=msg.getFunds())&&PortfolioOptimizationModel_pb.Funds.toObject(includeInstance,f),backteststartdate:(f=jspb.Message.getField(msg,4))==null?undefined:f,weights:(f=msg.getWeights())&&PortfolioOptimizationModel_pb.Weights.toObject(includeInstance,f)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest}
 */proto.SBProtoMessages.EqualAllocationsRequest.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.EqualAllocationsRequest();return proto.SBProtoMessages.EqualAllocationsRequest.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.EqualAllocationsRequest} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest}
 */proto.SBProtoMessages.EqualAllocationsRequest.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=msg.getInstrumentsMap();reader.readMessage(value,function(message,reader){jspb.Map.deserializeBinary(message,reader,jspb.BinaryReader.prototype.readString,jspb.BinaryReader.prototype.readMessage,proto.SBProtoMessages.PositionInfo.deserializeBinaryFromReader,"",new proto.SBProtoMessages.PositionInfo());});break;case 3:var value=new PortfolioOptimizationModel_pb.Funds();reader.readMessage(value,PortfolioOptimizationModel_pb.Funds.deserializeBinaryFromReader);msg.setFunds(value);break;case 4:var value=/** @type {number} */reader.readUint64();msg.setBackteststartdate(value);break;case 5:var value=new PortfolioOptimizationModel_pb.Weights();reader.readMessage(value,PortfolioOptimizationModel_pb.Weights.deserializeBinaryFromReader);msg.setWeights(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.EqualAllocationsRequest.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.EqualAllocationsRequest} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.EqualAllocationsRequest.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getInstrumentsMap(true);if(f&&f.getLength()>0){f.serializeBinary(2,writer,jspb.BinaryWriter.prototype.writeString,jspb.BinaryWriter.prototype.writeMessage,proto.SBProtoMessages.PositionInfo.serializeBinaryToWriter);}f=message.getFunds();if(f!=null){writer.writeMessage(3,f,PortfolioOptimizationModel_pb.Funds.serializeBinaryToWriter);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeUint64(4,f);}f=message.getWeights();if(f!=null){writer.writeMessage(5,f,PortfolioOptimizationModel_pb.Weights.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,2000));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * map<string, PositionInfo> instruments = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.SBProtoMessages.PositionInfo>}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.getInstrumentsMap=function(opt_noLazyCreate){return(/** @type {!jspb.Map<string,!proto.SBProtoMessages.PositionInfo>} */jspb.Message.getMapField(this,2,opt_noLazyCreate,proto.SBProtoMessages.PositionInfo));};/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.clearInstrumentsMap=function(){this.getInstrumentsMap().clear();return this;};/**
 * optional Funds funds = 3;
 * @return {?proto.SBProtoMessages.Funds}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.getFunds=function(){return(/** @type{?proto.SBProtoMessages.Funds} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.Funds,3));};/**
 * @param {?proto.SBProtoMessages.Funds|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
*/proto.SBProtoMessages.EqualAllocationsRequest.prototype.setFunds=function(value){return jspb.Message.setWrapperField(this,3,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.clearFunds=function(){return this.setFunds(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.hasFunds=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional uint64 backtestStartDate = 4;
 * @return {number}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.getBackteststartdate=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.setBackteststartdate=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.clearBackteststartdate=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.hasBackteststartdate=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional Weights weights = 5;
 * @return {?proto.SBProtoMessages.Weights}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.getWeights=function(){return(/** @type{?proto.SBProtoMessages.Weights} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.Weights,5));};/**
 * @param {?proto.SBProtoMessages.Weights|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
*/proto.SBProtoMessages.EqualAllocationsRequest.prototype.setWeights=function(value){return jspb.Message.setWrapperField(this,5,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsRequest} returns this
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.clearWeights=function(){return this.setWeights(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsRequest.prototype.hasWeights=function(){return jspb.Message.getField(this,5)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.EqualAllocationsResponse.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.EqualAllocationsResponse.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.EqualAllocationsResponse} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.EqualAllocationsResponse.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,2001),indexperformance:(f=msg.getIndexperformance())&&PortfolioOptimizationModel_pb.IndexPerformance.toObject(includeInstance,f),montecarlosimulation:(f=msg.getMontecarlosimulation())&&PortfolioOptimizationModel_pb.MonteCarloSimulation.toObject(includeInstance,f),optimizedpositions:(f=msg.getOptimizedpositions())&&PortfolioOptimizationModel_pb.OptimizedPositions.toObject(includeInstance,f),portfolioperformance:(f=msg.getPortfolioperformance())&&PortfolioOptimizationModel_pb.PortfolioPerformance.toObject(includeInstance,f)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse}
 */proto.SBProtoMessages.EqualAllocationsResponse.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.EqualAllocationsResponse();return proto.SBProtoMessages.EqualAllocationsResponse.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.EqualAllocationsResponse} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse}
 */proto.SBProtoMessages.EqualAllocationsResponse.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=new PortfolioOptimizationModel_pb.IndexPerformance();reader.readMessage(value,PortfolioOptimizationModel_pb.IndexPerformance.deserializeBinaryFromReader);msg.setIndexperformance(value);break;case 3:var value=new PortfolioOptimizationModel_pb.MonteCarloSimulation();reader.readMessage(value,PortfolioOptimizationModel_pb.MonteCarloSimulation.deserializeBinaryFromReader);msg.setMontecarlosimulation(value);break;case 4:var value=new PortfolioOptimizationModel_pb.OptimizedPositions();reader.readMessage(value,PortfolioOptimizationModel_pb.OptimizedPositions.deserializeBinaryFromReader);msg.setOptimizedpositions(value);break;case 5:var value=new PortfolioOptimizationModel_pb.PortfolioPerformance();reader.readMessage(value,PortfolioOptimizationModel_pb.PortfolioPerformance.deserializeBinaryFromReader);msg.setPortfolioperformance(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.EqualAllocationsResponse.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.EqualAllocationsResponse} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.EqualAllocationsResponse.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getIndexperformance();if(f!=null){writer.writeMessage(2,f,PortfolioOptimizationModel_pb.IndexPerformance.serializeBinaryToWriter);}f=message.getMontecarlosimulation();if(f!=null){writer.writeMessage(3,f,PortfolioOptimizationModel_pb.MonteCarloSimulation.serializeBinaryToWriter);}f=message.getOptimizedpositions();if(f!=null){writer.writeMessage(4,f,PortfolioOptimizationModel_pb.OptimizedPositions.serializeBinaryToWriter);}f=message.getPortfolioperformance();if(f!=null){writer.writeMessage(5,f,PortfolioOptimizationModel_pb.PortfolioPerformance.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,2001));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional IndexPerformance indexPerformance = 2;
 * @return {?proto.SBProtoMessages.IndexPerformance}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.getIndexperformance=function(){return(/** @type{?proto.SBProtoMessages.IndexPerformance} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.IndexPerformance,2));};/**
 * @param {?proto.SBProtoMessages.IndexPerformance|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
*/proto.SBProtoMessages.EqualAllocationsResponse.prototype.setIndexperformance=function(value){return jspb.Message.setWrapperField(this,2,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.clearIndexperformance=function(){return this.setIndexperformance(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.hasIndexperformance=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional MonteCarloSimulation monteCarloSimulation = 3;
 * @return {?proto.SBProtoMessages.MonteCarloSimulation}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.getMontecarlosimulation=function(){return(/** @type{?proto.SBProtoMessages.MonteCarloSimulation} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.MonteCarloSimulation,3));};/**
 * @param {?proto.SBProtoMessages.MonteCarloSimulation|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
*/proto.SBProtoMessages.EqualAllocationsResponse.prototype.setMontecarlosimulation=function(value){return jspb.Message.setWrapperField(this,3,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.clearMontecarlosimulation=function(){return this.setMontecarlosimulation(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.hasMontecarlosimulation=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional OptimizedPositions optimizedPositions = 4;
 * @return {?proto.SBProtoMessages.OptimizedPositions}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.getOptimizedpositions=function(){return(/** @type{?proto.SBProtoMessages.OptimizedPositions} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.OptimizedPositions,4));};/**
 * @param {?proto.SBProtoMessages.OptimizedPositions|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
*/proto.SBProtoMessages.EqualAllocationsResponse.prototype.setOptimizedpositions=function(value){return jspb.Message.setWrapperField(this,4,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.clearOptimizedpositions=function(){return this.setOptimizedpositions(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.hasOptimizedpositions=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional PortfolioPerformance portfolioPerformance = 5;
 * @return {?proto.SBProtoMessages.PortfolioPerformance}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.getPortfolioperformance=function(){return(/** @type{?proto.SBProtoMessages.PortfolioPerformance} */jspb.Message.getWrapperField(this,PortfolioOptimizationModel_pb.PortfolioPerformance,5));};/**
 * @param {?proto.SBProtoMessages.PortfolioPerformance|undefined} value
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
*/proto.SBProtoMessages.EqualAllocationsResponse.prototype.setPortfolioperformance=function(value){return jspb.Message.setWrapperField(this,5,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.EqualAllocationsResponse} returns this
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.clearPortfolioperformance=function(){return this.setPortfolioperformance(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.EqualAllocationsResponse.prototype.hasPortfolioperformance=function(){return jspb.Message.getField(this,5)!=null;};goog.object.extend(exports,proto.SBProtoMessages);