"use strict";// source: MarginCallMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);goog.exportSymbol('proto.SBProtoMessages.ProtoMarginCallEvent',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoMarginCallEvent=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoMarginCallEvent,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoMarginCallEvent.displayName='proto.SBProtoMessages.ProtoMarginCallEvent';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoMarginCallEvent.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoMarginCallEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoMarginCallEvent.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,877),marginpercentage:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f,marginpercentagethreshold:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent}
 */proto.SBProtoMessages.ProtoMarginCallEvent.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoMarginCallEvent();return proto.SBProtoMessages.ProtoMarginCallEvent.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoMarginCallEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent}
 */proto.SBProtoMessages.ProtoMarginCallEvent.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setMarginpercentage(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setMarginpercentagethreshold(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoMarginCallEvent.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoMarginCallEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoMarginCallEvent.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,877));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double MarginPercentage = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.getMarginpercentage=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.setMarginpercentage=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.clearMarginpercentage=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.hasMarginpercentage=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double MarginPercentageThreshold = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.getMarginpercentagethreshold=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.setMarginpercentagethreshold=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMarginCallEvent} returns this
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.clearMarginpercentagethreshold=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMarginCallEvent.prototype.hasMarginpercentagethreshold=function(){return jspb.Message.getField(this,3)!=null;};goog.object.extend(exports,proto.SBProtoMessages);