"use strict";// source: TopInstrumentsModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoInstrumentSuggestion',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoInstrumentSuggestionCategory',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoInstrumentSuggestion=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoInstrumentSuggestion.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoInstrumentSuggestion,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoInstrumentSuggestion.displayName='proto.SBProtoMessages.ProtoInstrumentSuggestion';}/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.repeatedFields_=[2];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoInstrumentSuggestion.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoInstrumentSuggestion} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoInstrumentSuggestion.toObject=function(includeInstance,msg){var f,obj={category:jspb.Message.getFieldWithDefault(msg,1,0),symbolsList:(f=jspb.Message.getRepeatedField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoInstrumentSuggestion();return proto.SBProtoMessages.ProtoInstrumentSuggestion.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoInstrumentSuggestion} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoInstrumentSuggestionCategory} */reader.readEnum();msg.setCategory(value);break;case 2:var value=/** @type {string} */reader.readString();msg.addSymbols(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoInstrumentSuggestion.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoInstrumentSuggestion} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoInstrumentSuggestionCategory} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getSymbolsList();if(f.length>0){writer.writeRepeatedString(2,f);}};/**
 * optional ProtoInstrumentSuggestionCategory category = 1;
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestionCategory}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.getCategory=function(){return(/** @type {!proto.SBProtoMessages.ProtoInstrumentSuggestionCategory} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {!proto.SBProtoMessages.ProtoInstrumentSuggestionCategory} value
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion} returns this
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.setCategory=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion} returns this
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.clearCategory=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.hasCategory=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated string symbols = 2;
 * @return {!Array<string>}
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.getSymbolsList=function(){return(/** @type {!Array<string>} */jspb.Message.getRepeatedField(this,2));};/**
 * @param {!Array<string>} value
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion} returns this
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.setSymbolsList=function(value){return jspb.Message.setField(this,2,value||[]);};/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion} returns this
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.addSymbols=function(value,opt_index){return jspb.Message.addToRepeatedField(this,2,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoInstrumentSuggestion} returns this
 */proto.SBProtoMessages.ProtoInstrumentSuggestion.prototype.clearSymbolsList=function(){return this.setSymbolsList([]);};/**
 * @enum {number}
 */proto.SBProtoMessages.ProtoInstrumentSuggestionCategory={MOST_VIEWED:0,BIGGEST_TRADED_VALUE:1,MOST_MENTIONED_IN_NEWS:2,MOST_TRADED:3,BIGGEST_TRADED_VALUE_ON_PRICE_PROVIDER:4};goog.object.extend(exports,proto.SBProtoMessages);