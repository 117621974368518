"use strict";// source: QXErrorCode.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoErrorCode',null,global);/**
 * @enum {number}
 */proto.SBProtoMessages.ProtoErrorCode={UNKNOWN_ERROR:0,UNSUPPORTED_MESSAGE:1,AUTHORIZATION_FAILED:2,ACCOUNT_DISABLED:3,SERVER_NOT_AVAILABLE:4,NOT_SUPPORTED_OPERATION:5,REQUEST_FAILED:21,REQUEST_REQUOTED:22,REQUEST_REJECTED:23,REQUEST_EXPIRED:24,REQUEST_OFF_QUOTES:25,REQUEST_BAD_TRANSACTION_TYPE:26,REQUEST_TOO_MANY_REQUESTS:27,REQUEST_VOLUME_RESTRICTED:28,WRONG_PARAMETERS:100,INVALID_TICK_SIZE:101,INVALID_PRICE:102,INVALID_SL_PRICE:103,INVALID_TP_PRICE:104,INVALID_VOLUME:105,INVALID_STOPS:106,INVALID_ORDER_COMMAND:107,INVALID_EXPIRATION_TIME:108,TRADE_DISABLED_OR_MARKET_CLOSED:200,LONG_ONLY_ALLOWED:201,CLOSE_ONLY_ALLOWED:202,EXPIRATION_DENIED:203,NOT_ENOUGH_MARGIN:204,NO_QUOTES:205,USER_INFO_GET_FAILED:401,GROUPS_GET_FAILED:402,SYMBOL_GET_FAILED:403,ORDER_GET_FAILED:404,ORDER_ID_NOT_DEFINED:405,WRONG_USER_ID:406,ORDER_OPEN_FAILED:501,ORDER_CLOSE_FAILED:502,ORDER_UPDATE_FAILED:503,ORDER_ALREADY_CLOSED:510,ORDER_ALREADY_ACTIVATED:511,ORDER_ALREADY_DELETED:512,POSITION_FROZEN:520,POSITION_STILL_OPEN:521,POSITION_NOT_FOUND:522,SYMBOL_NOT_FOUND:523,EMPTY_REQUEST:524,BAD_REQUEST:525,CLIENT_NOT_FOUND:526,PRICE_STREAM_DISABLED:530,PRICE_STREAM_NOT_FOUND:531,SUBSCRIPTION_EXISTS:532,NOT_ENOUGH_FUNDS:540,NUMBER_OF_MARKET_OPENING_ALERTS_EXCEEDED:550,NOTIFICATION_SETTINGS_NOT_FOUND:551};goog.object.extend(exports,proto.SBProtoMessages);