"use strict";// source: WTSProtoModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoAccountDealSummary',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPopularSymbols',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPreviousSymbolClosePrice',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoAccountDealSummary=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoAccountDealSummary,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoAccountDealSummary.displayName='proto.SBProtoMessages.ProtoAccountDealSummary';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoPopularSymbols=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoPopularSymbols.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoPopularSymbols,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoPopularSymbols.displayName='proto.SBProtoMessages.ProtoPopularSymbols';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoPreviousSymbolClosePrice,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.displayName='proto.SBProtoMessages.ProtoPreviousSymbolClosePrice';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoAccountDealSummary.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoAccountDealSummary} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoAccountDealSummary.toObject=function(includeInstance,msg){var f,obj={traderid:(f=jspb.Message.getField(msg,1))==null?undefined:f,totaldealgrossprofit:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f,totaldividends:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f,totalcommissions:(f=jspb.Message.getOptionalFloatingPointField(msg,4))==null?undefined:f,totalfees:(f=jspb.Message.getOptionalFloatingPointField(msg,5))==null?undefined:f,totalsubscriptions:(f=jspb.Message.getOptionalFloatingPointField(msg,6))==null?undefined:f,totaldeposit:(f=jspb.Message.getOptionalFloatingPointField(msg,7))==null?undefined:f,totalwithdraw:(f=jspb.Message.getOptionalFloatingPointField(msg,8))==null?undefined:f,totalcorrections:(f=jspb.Message.getOptionalFloatingPointField(msg,9))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary}
 */proto.SBProtoMessages.ProtoAccountDealSummary.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoAccountDealSummary();return proto.SBProtoMessages.ProtoAccountDealSummary.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoAccountDealSummary} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary}
 */proto.SBProtoMessages.ProtoAccountDealSummary.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readInt64();msg.setTraderid(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setTotaldealgrossprofit(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setTotaldividends(value);break;case 4:var value=/** @type {number} */reader.readDouble();msg.setTotalcommissions(value);break;case 5:var value=/** @type {number} */reader.readDouble();msg.setTotalfees(value);break;case 6:var value=/** @type {number} */reader.readDouble();msg.setTotalsubscriptions(value);break;case 7:var value=/** @type {number} */reader.readDouble();msg.setTotaldeposit(value);break;case 8:var value=/** @type {number} */reader.readDouble();msg.setTotalwithdraw(value);break;case 9:var value=/** @type {number} */reader.readDouble();msg.setTotalcorrections(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoAccountDealSummary.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoAccountDealSummary} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoAccountDealSummary.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeInt64(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeDouble(4,f);}f=/** @type {number} */jspb.Message.getField(message,5);if(f!=null){writer.writeDouble(5,f);}f=/** @type {number} */jspb.Message.getField(message,6);if(f!=null){writer.writeDouble(6,f);}f=/** @type {number} */jspb.Message.getField(message,7);if(f!=null){writer.writeDouble(7,f);}f=/** @type {number} */jspb.Message.getField(message,8);if(f!=null){writer.writeDouble(8,f);}f=/** @type {number} */jspb.Message.getField(message,9);if(f!=null){writer.writeDouble(9,f);}};/**
 * optional int64 traderId = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTraderid=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTraderid=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTraderid=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTraderid=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double totalDealGrossProfit = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotaldealgrossprofit=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotaldealgrossprofit=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotaldealgrossprofit=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotaldealgrossprofit=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double totalDividends = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotaldividends=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotaldividends=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotaldividends=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotaldividends=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional double totalCommissions = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotalcommissions=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,4,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotalcommissions=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotalcommissions=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotalcommissions=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional double totalFees = 5;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotalfees=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,5,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotalfees=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotalfees=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotalfees=function(){return jspb.Message.getField(this,5)!=null;};/**
 * optional double totalSubscriptions = 6;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotalsubscriptions=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,6,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotalsubscriptions=function(value){return jspb.Message.setField(this,6,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotalsubscriptions=function(){return jspb.Message.setField(this,6,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotalsubscriptions=function(){return jspb.Message.getField(this,6)!=null;};/**
 * optional double totalDeposit = 7;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotaldeposit=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,7,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotaldeposit=function(value){return jspb.Message.setField(this,7,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotaldeposit=function(){return jspb.Message.setField(this,7,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotaldeposit=function(){return jspb.Message.getField(this,7)!=null;};/**
 * optional double totalWithdraw = 8;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotalwithdraw=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,8,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotalwithdraw=function(value){return jspb.Message.setField(this,8,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotalwithdraw=function(){return jspb.Message.setField(this,8,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotalwithdraw=function(){return jspb.Message.getField(this,8)!=null;};/**
 * optional double totalCorrections = 9;
 * @return {number}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.getTotalcorrections=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,9,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.setTotalcorrections=function(value){return jspb.Message.setField(this,9,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoAccountDealSummary} returns this
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.clearTotalcorrections=function(){return jspb.Message.setField(this,9,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoAccountDealSummary.prototype.hasTotalcorrections=function(){return jspb.Message.getField(this,9)!=null;};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoPopularSymbols.repeatedFields_=[2];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoPopularSymbols.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoPopularSymbols.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoPopularSymbols} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoPopularSymbols.toObject=function(includeInstance,msg){var f,obj={listkey:(f=jspb.Message.getField(msg,1))==null?undefined:f,symbolsList:(f=jspb.Message.getRepeatedField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols}
 */proto.SBProtoMessages.ProtoPopularSymbols.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoPopularSymbols();return proto.SBProtoMessages.ProtoPopularSymbols.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoPopularSymbols} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols}
 */proto.SBProtoMessages.ProtoPopularSymbols.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {string} */reader.readString();msg.setListkey(value);break;case 2:var value=/** @type {string} */reader.readString();msg.addSymbols(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoPopularSymbols.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoPopularSymbols} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoPopularSymbols.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {string} */jspb.Message.getField(message,1);if(f!=null){writer.writeString(1,f);}f=message.getSymbolsList();if(f.length>0){writer.writeRepeatedString(2,f);}};/**
 * optional string ListKey = 1;
 * @return {string}
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.getListkey=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,1,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols} returns this
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.setListkey=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols} returns this
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.clearListkey=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.hasListkey=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated string Symbols = 2;
 * @return {!Array<string>}
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.getSymbolsList=function(){return(/** @type {!Array<string>} */jspb.Message.getRepeatedField(this,2));};/**
 * @param {!Array<string>} value
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols} returns this
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.setSymbolsList=function(value){return jspb.Message.setField(this,2,value||[]);};/**
 * @param {string} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols} returns this
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.addSymbols=function(value,opt_index){return jspb.Message.addToRepeatedField(this,2,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoPopularSymbols} returns this
 */proto.SBProtoMessages.ProtoPopularSymbols.prototype.clearSymbolsList=function(){return this.setSymbolsList([]);};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.toObject=function(includeInstance,msg){var f,obj={symbolname:(f=jspb.Message.getField(msg,1))==null?undefined:f,closeprice:(f=jspb.Message.getField(msg,2))==null?undefined:f,utctimestampinminutes:(f=jspb.Message.getField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoPreviousSymbolClosePrice();return proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {string} */reader.readString();msg.setSymbolname(value);break;case 2:var value=/** @type {number} */reader.readUint64();msg.setCloseprice(value);break;case 3:var value=/** @type {number} */reader.readUint32();msg.setUtctimestampinminutes(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {string} */jspb.Message.getField(message,1);if(f!=null){writer.writeString(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeUint64(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeUint32(3,f);}};/**
 * optional string symbolName = 1;
 * @return {string}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.getSymbolname=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,1,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.setSymbolname=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.clearSymbolname=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.hasSymbolname=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional uint64 closePrice = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.getCloseprice=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.setCloseprice=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.clearCloseprice=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.hasCloseprice=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional uint32 utcTimestampInMinutes = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.getUtctimestampinminutes=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,3,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.setUtctimestampinminutes=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPreviousSymbolClosePrice} returns this
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.clearUtctimestampinminutes=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPreviousSymbolClosePrice.prototype.hasUtctimestampinminutes=function(){return jspb.Message.getField(this,3)!=null;};goog.object.extend(exports,proto.SBProtoMessages);