"use strict";// source: PerformanceCalculationMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);goog.exportSymbol('proto.SBProtoMessages.PerformanceTimeframe',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPcPerformance',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.displayName='proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.displayName='proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoPcPerformance=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoPcPerformance,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoPcPerformance.displayName='proto.SBProtoMessages.ProtoPcPerformance';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1300),from:(f=jspb.Message.getField(msg,3))==null?undefined:f,to:(f=jspb.Message.getField(msg,4))==null?undefined:f,limit:(f=jspb.Message.getField(msg,5))==null?undefined:f,nextpagecursor:(f=jspb.Message.getField(msg,6))==null?undefined:f,timeframe:(f=jspb.Message.getField(msg,7))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq();return proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 3:var value=/** @type {number} */reader.readUint64();msg.setFrom(value);break;case 4:var value=/** @type {number} */reader.readUint64();msg.setTo(value);break;case 5:var value=/** @type {number} */reader.readInt32();msg.setLimit(value);break;case 6:var value=/** @type {string} */reader.readString();msg.setNextpagecursor(value);break;case 7:var value=/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */reader.readEnum();msg.setTimeframe(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeUint64(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeUint64(4,f);}f=/** @type {number} */jspb.Message.getField(message,5);if(f!=null){writer.writeInt32(5,f);}f=/** @type {string} */jspb.Message.getField(message,6);if(f!=null){writer.writeString(6,f);}f=/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */jspb.Message.getField(message,7);if(f!=null){writer.writeEnum(7,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1300));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional uint64 from = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getFrom=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,3,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setFrom=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearFrom=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasFrom=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional uint64 to = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getTo=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setTo=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearTo=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasTo=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional int32 limit = 5;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getLimit=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,5,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setLimit=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearLimit=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasLimit=function(){return jspb.Message.getField(this,5)!=null;};/**
 * optional string nextPageCursor = 6;
 * @return {string}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getNextpagecursor=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,6,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setNextpagecursor=function(value){return jspb.Message.setField(this,6,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearNextpagecursor=function(){return jspb.Message.setField(this,6,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasNextpagecursor=function(){return jspb.Message.getField(this,6)!=null;};/**
 * optional PerformanceTimeframe timeframe = 7;
 * @return {!proto.SBProtoMessages.PerformanceTimeframe}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.getTimeframe=function(){return(/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */jspb.Message.getFieldWithDefault(this,7,0));};/**
 * @param {!proto.SBProtoMessages.PerformanceTimeframe} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.setTimeframe=function(value){return jspb.Message.setField(this,7,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.clearTimeframe=function(){return jspb.Message.setField(this,7,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceReq.prototype.hasTimeframe=function(){return jspb.Message.getField(this,7)!=null;};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.repeatedFields_=[2];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,1301),investorperformancesList:jspb.Message.toObjectList(msg.getInvestorperformancesList(),proto.SBProtoMessages.ProtoPcPerformance.toObject,includeInstance),nextpagecursor:(f=jspb.Message.getField(msg,3))==null?undefined:f,timeframe:(f=jspb.Message.getField(msg,4))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes();return proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=new proto.SBProtoMessages.ProtoPcPerformance();reader.readMessage(value,proto.SBProtoMessages.ProtoPcPerformance.deserializeBinaryFromReader);msg.addInvestorperformances(value);break;case 3:var value=/** @type {string} */reader.readString();msg.setNextpagecursor(value);break;case 4:var value=/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */reader.readEnum();msg.setTimeframe(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=message.getInvestorperformancesList();if(f.length>0){writer.writeRepeatedMessage(2,f,proto.SBProtoMessages.ProtoPcPerformance.serializeBinaryToWriter);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}f=/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */jspb.Message.getField(message,4);if(f!=null){writer.writeEnum(4,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,1301));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * repeated ProtoPcPerformance investorPerformances = 2;
 * @return {!Array<!proto.SBProtoMessages.ProtoPcPerformance>}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.getInvestorperformancesList=function(){return(/** @type{!Array<!proto.SBProtoMessages.ProtoPcPerformance>} */jspb.Message.getRepeatedWrapperField(this,proto.SBProtoMessages.ProtoPcPerformance,2));};/**
 * @param {!Array<!proto.SBProtoMessages.ProtoPcPerformance>} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
*/proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.setInvestorperformancesList=function(value){return jspb.Message.setRepeatedWrapperField(this,2,value);};/**
 * @param {!proto.SBProtoMessages.ProtoPcPerformance=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.ProtoPcPerformance}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.addInvestorperformances=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,2,opt_value,proto.SBProtoMessages.ProtoPcPerformance,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.clearInvestorperformancesList=function(){return this.setInvestorperformancesList([]);};/**
 * optional string nextPageCursor = 3;
 * @return {string}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.getNextpagecursor=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.setNextpagecursor=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.clearNextpagecursor=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.hasNextpagecursor=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional PerformanceTimeframe timeframe = 4;
 * @return {!proto.SBProtoMessages.PerformanceTimeframe}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.getTimeframe=function(){return(/** @type {!proto.SBProtoMessages.PerformanceTimeframe} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {!proto.SBProtoMessages.PerformanceTimeframe} value
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.setTimeframe=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes} returns this
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.clearTimeframe=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcHistoricalPerformanceRes.prototype.hasTimeframe=function(){return jspb.Message.getField(this,4)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoPcPerformance.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoPcPerformance.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoPcPerformance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoPcPerformance.toObject=function(includeInstance,msg){var f,obj={timeweightedreturn:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,timestamp:(f=jspb.Message.getField(msg,2))==null?undefined:f,moneyweightedreturn:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoPcPerformance}
 */proto.SBProtoMessages.ProtoPcPerformance.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoPcPerformance();return proto.SBProtoMessages.ProtoPcPerformance.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoPcPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoPcPerformance}
 */proto.SBProtoMessages.ProtoPcPerformance.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setTimeweightedreturn(value);break;case 2:var value=/** @type {number} */reader.readUint64();msg.setTimestamp(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setMoneyweightedreturn(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoPcPerformance.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoPcPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoPcPerformance.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeUint64(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}};/**
 * optional double timeWeightedReturn = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.getTimeweightedreturn=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.setTimeweightedreturn=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.clearTimeweightedreturn=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.hasTimeweightedreturn=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional uint64 timestamp = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.getTimestamp=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.setTimestamp=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.clearTimestamp=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.hasTimestamp=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double moneyWeightedReturn = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.getMoneyweightedreturn=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.setMoneyweightedreturn=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoPcPerformance} returns this
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.clearMoneyweightedreturn=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoPcPerformance.prototype.hasMoneyweightedreturn=function(){return jspb.Message.getField(this,3)!=null;};/**
 * @enum {number}
 */proto.SBProtoMessages.PerformanceTimeframe={SINCE_INCEPTION:0,MONTH_TO_DATE:1,YEAR_TO_DATE:2,LAST_SEVEN_DAYS:3,LAST_ONE_MONTH:4,LAST_THREE_MONTHS:5,LAST_TWELVE_MONTHS:6,LAST_THREE_YEARS:7,LAST_FIVE_YEARS:8,LAST_TEN_YEARS:9};goog.object.extend(exports,proto.SBProtoMessages);