"use strict";// source: ModelMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoMessage',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoPayloadType',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoMessage=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoMessage,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoMessage.displayName='proto.SBProtoMessages.ProtoMessage';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoMessage.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoMessage.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoMessage} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoMessage.toObject=function(includeInstance,msg){var f,obj={payloadtype:(f=jspb.Message.getField(msg,1))==null?undefined:f,payload:msg.getPayload_asB64(),clientmsgid:(f=jspb.Message.getField(msg,3))==null?undefined:f,payloadstring:(f=jspb.Message.getField(msg,4))==null?undefined:f,sessiontoken:(f=jspb.Message.getField(msg,5))==null?undefined:f,clientsessiontoken:(f=jspb.Message.getField(msg,6))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoMessage}
 */proto.SBProtoMessages.ProtoMessage.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoMessage();return proto.SBProtoMessages.ProtoMessage.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoMessage} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoMessage}
 */proto.SBProtoMessages.ProtoMessage.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readUint32();msg.setPayloadtype(value);break;case 2:var value=/** @type {!Uint8Array} */reader.readBytes();msg.setPayload(value);break;case 3:var value=/** @type {string} */reader.readString();msg.setClientmsgid(value);break;case 4:var value=/** @type {string} */reader.readString();msg.setPayloadstring(value);break;case 5:var value=/** @type {string} */reader.readString();msg.setSessiontoken(value);break;case 6:var value=/** @type {string} */reader.readString();msg.setClientsessiontoken(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoMessage.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoMessage.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoMessage} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoMessage.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeUint32(1,f);}f=/** @type {!(string|Uint8Array)} */jspb.Message.getField(message,2);if(f!=null){writer.writeBytes(2,f);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}f=/** @type {string} */jspb.Message.getField(message,4);if(f!=null){writer.writeString(4,f);}f=/** @type {string} */jspb.Message.getField(message,5);if(f!=null){writer.writeString(5,f);}f=/** @type {string} */jspb.Message.getField(message,6);if(f!=null){writer.writeString(6,f);}};/**
 * required uint32 payloadType = 1;
 * @return {number}
 */proto.SBProtoMessages.ProtoMessage.prototype.getPayloadtype=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,1,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional bytes payload = 2;
 * @return {!(string|Uint8Array)}
 */proto.SBProtoMessages.ProtoMessage.prototype.getPayload=function(){return(/** @type {!(string|Uint8Array)} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * optional bytes payload = 2;
 * This is a type-conversion wrapper around `getPayload()`
 * @return {string}
 */proto.SBProtoMessages.ProtoMessage.prototype.getPayload_asB64=function(){return(/** @type {string} */jspb.Message.bytesAsB64(this.getPayload()));};/**
 * optional bytes payload = 2;
 * Note that Uint8Array is not supported on all browsers.
 * @see http://caniuse.com/Uint8Array
 * This is a type-conversion wrapper around `getPayload()`
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoMessage.prototype.getPayload_asU8=function(){return(/** @type {!Uint8Array} */jspb.Message.bytesAsU8(this.getPayload()));};/**
 * @param {!(string|Uint8Array)} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setPayload=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearPayload=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasPayload=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional string clientMsgId = 3;
 * @return {string}
 */proto.SBProtoMessages.ProtoMessage.prototype.getClientmsgid=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setClientmsgid=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearClientmsgid=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasClientmsgid=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional string payloadString = 4;
 * @return {string}
 */proto.SBProtoMessages.ProtoMessage.prototype.getPayloadstring=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,4,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setPayloadstring=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearPayloadstring=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasPayloadstring=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional string sessionToken = 5;
 * @return {string}
 */proto.SBProtoMessages.ProtoMessage.prototype.getSessiontoken=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,5,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setSessiontoken=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearSessiontoken=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasSessiontoken=function(){return jspb.Message.getField(this,5)!=null;};/**
 * optional string clientSessionToken = 6;
 * @return {string}
 */proto.SBProtoMessages.ProtoMessage.prototype.getClientsessiontoken=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,6,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.setClientsessiontoken=function(value){return jspb.Message.setField(this,6,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoMessage} returns this
 */proto.SBProtoMessages.ProtoMessage.prototype.clearClientsessiontoken=function(){return jspb.Message.setField(this,6,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoMessage.prototype.hasClientsessiontoken=function(){return jspb.Message.getField(this,6)!=null;};/**
 * @enum {number}
 */proto.SBProtoMessages.ProtoPayloadType={PROTO_MESSAGE:5,ERROR_RES:50,PING_REQ:52,PING_RES:53};goog.object.extend(exports,proto.SBProtoMessages);