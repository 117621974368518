"use strict";// source: CorporateActionsModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();goog.exportSymbol('proto.SBProtoMessages.ProtoCashDividend',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoCashDividend=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoCashDividend,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoCashDividend.displayName='proto.SBProtoMessages.ProtoCashDividend';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoCashDividend.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoCashDividend.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoCashDividend} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoCashDividend.toObject=function(includeInstance,msg){var f,obj={instrumentname:(f=jspb.Message.getField(msg,1))==null?undefined:f,exdividenddate:(f=jspb.Message.getField(msg,2))==null?undefined:f,paymentdate:(f=jspb.Message.getField(msg,3))==null?undefined:f,amount:(f=jspb.Message.getField(msg,4))==null?undefined:f,currency:(f=jspb.Message.getField(msg,5))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoCashDividend}
 */proto.SBProtoMessages.ProtoCashDividend.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoCashDividend();return proto.SBProtoMessages.ProtoCashDividend.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoCashDividend} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoCashDividend}
 */proto.SBProtoMessages.ProtoCashDividend.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {string} */reader.readString();msg.setInstrumentname(value);break;case 2:var value=/** @type {number} */reader.readInt64();msg.setExdividenddate(value);break;case 3:var value=/** @type {number} */reader.readInt64();msg.setPaymentdate(value);break;case 4:var value=/** @type {number} */reader.readInt64();msg.setAmount(value);break;case 5:var value=/** @type {string} */reader.readString();msg.setCurrency(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoCashDividend.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoCashDividend} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoCashDividend.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {string} */jspb.Message.getField(message,1);if(f!=null){writer.writeString(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeInt64(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeInt64(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeInt64(4,f);}f=/** @type {string} */jspb.Message.getField(message,5);if(f!=null){writer.writeString(5,f);}};/**
 * optional string instrumentName = 1;
 * @return {string}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.getInstrumentname=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,1,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.setInstrumentname=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.clearInstrumentname=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.hasInstrumentname=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional int64 exDividendDate = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.getExdividenddate=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.setExdividenddate=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.clearExdividenddate=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.hasExdividenddate=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional int64 paymentDate = 3;
 * @return {number}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.getPaymentdate=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,3,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.setPaymentdate=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.clearPaymentdate=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.hasPaymentdate=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional int64 amount = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.getAmount=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.setAmount=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.clearAmount=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.hasAmount=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional string currency = 5;
 * @return {string}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.getCurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,5,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.setCurrency=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoCashDividend} returns this
 */proto.SBProtoMessages.ProtoCashDividend.prototype.clearCurrency=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoCashDividend.prototype.hasCurrency=function(){return jspb.Message.getField(this,5)!=null;};goog.object.extend(exports,proto.SBProtoMessages);