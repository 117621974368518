"use strict";// source: CommonMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var ModelMessages_pb=require('./ModelMessages_pb.js');goog.object.extend(proto,ModelMessages_pb);var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);var QXErrorCode_pb=require('./QXErrorCode_pb.js');goog.object.extend(proto,QXErrorCode_pb);goog.exportSymbol('proto.SBProtoMessages.ProtoErrorRes',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoSuccessRes',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoVersionReq',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoVersionRes',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoErrorRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoErrorRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoErrorRes.displayName='proto.SBProtoMessages.ProtoErrorRes';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoSuccessRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoSuccessRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoSuccessRes.displayName='proto.SBProtoMessages.ProtoSuccessRes';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoVersionReq=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoVersionReq,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoVersionReq.displayName='proto.SBProtoMessages.ProtoVersionReq';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoVersionRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.ProtoVersionRes.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.ProtoVersionRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoVersionRes.displayName='proto.SBProtoMessages.ProtoVersionRes';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoErrorRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoErrorRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoErrorRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoErrorRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,50),errorcode:jspb.Message.getFieldWithDefault(msg,2,0),description:(f=jspb.Message.getField(msg,3))==null?undefined:f,reqpayloadtype:(f=jspb.Message.getField(msg,4))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoErrorRes}
 */proto.SBProtoMessages.ProtoErrorRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoErrorRes();return proto.SBProtoMessages.ProtoErrorRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoErrorRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoErrorRes}
 */proto.SBProtoMessages.ProtoErrorRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=/** @type {!proto.SBProtoMessages.ProtoErrorCode} */reader.readEnum();msg.setErrorcode(value);break;case 3:var value=/** @type {string} */reader.readString();msg.setDescription(value);break;case 4:var value=/** @type {number} */reader.readUint32();msg.setReqpayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoErrorRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoErrorRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoErrorRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {!proto.SBProtoMessages.ProtoErrorCode} */jspb.Message.getField(message,2);if(f!=null){writer.writeEnum(2,f);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeUint32(4,f);}};/**
 * optional ProtoPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoPayloadType}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoPayloadType} */jspb.Message.getFieldWithDefault(this,1,50));};/**
 * @param {!proto.SBProtoMessages.ProtoPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required ProtoErrorCode errorCode = 2;
 * @return {!proto.SBProtoMessages.ProtoErrorCode}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.getErrorcode=function(){return(/** @type {!proto.SBProtoMessages.ProtoErrorCode} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {!proto.SBProtoMessages.ProtoErrorCode} value
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.setErrorcode=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.clearErrorcode=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.hasErrorcode=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional string description = 3;
 * @return {string}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.getDescription=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.setDescription=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.clearDescription=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.hasDescription=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional uint32 reqPayloadType = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.getReqpayloadtype=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.setReqpayloadtype=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoErrorRes} returns this
 */proto.SBProtoMessages.ProtoErrorRes.prototype.clearReqpayloadtype=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoErrorRes.prototype.hasReqpayloadtype=function(){return jspb.Message.getField(this,4)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoSuccessRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoSuccessRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoSuccessRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoSuccessRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,139)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoSuccessRes}
 */proto.SBProtoMessages.ProtoSuccessRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoSuccessRes();return proto.SBProtoMessages.ProtoSuccessRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoSuccessRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoSuccessRes}
 */proto.SBProtoMessages.ProtoSuccessRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoSuccessRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoSuccessRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoSuccessRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoSuccessRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoSuccessRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,139));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoSuccessRes} returns this
 */proto.SBProtoMessages.ProtoSuccessRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoSuccessRes} returns this
 */proto.SBProtoMessages.ProtoSuccessRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoSuccessRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoVersionReq.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoVersionReq.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoVersionReq} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoVersionReq.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,154)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoVersionReq}
 */proto.SBProtoMessages.ProtoVersionReq.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoVersionReq();return proto.SBProtoMessages.ProtoVersionReq.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoVersionReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoVersionReq}
 */proto.SBProtoMessages.ProtoVersionReq.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoVersionReq.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoVersionReq.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoVersionReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoVersionReq.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoVersionReq.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,154));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoVersionReq} returns this
 */proto.SBProtoMessages.ProtoVersionReq.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoVersionReq} returns this
 */proto.SBProtoMessages.ProtoVersionReq.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoVersionReq.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.ProtoVersionRes.repeatedFields_=[3];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoVersionRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoVersionRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoVersionRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoVersionRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,155),version:(f=jspb.Message.getField(msg,2))==null?undefined:f,plugininfoList:jspb.Message.toObjectList(msg.getPlugininfoList(),QXCommonModel_pb.PluginInfo.toObject,includeInstance)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoVersionRes}
 */proto.SBProtoMessages.ProtoVersionRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoVersionRes();return proto.SBProtoMessages.ProtoVersionRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoVersionRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoVersionRes}
 */proto.SBProtoMessages.ProtoVersionRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setVersion(value);break;case 3:var value=new QXCommonModel_pb.PluginInfo();reader.readMessage(value,QXCommonModel_pb.PluginInfo.deserializeBinaryFromReader);msg.addPlugininfo(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoVersionRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoVersionRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoVersionRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}f=message.getPlugininfoList();if(f.length>0){writer.writeRepeatedMessage(3,f,QXCommonModel_pb.PluginInfo.serializeBinaryToWriter);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,155));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
 */proto.SBProtoMessages.ProtoVersionRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
 */proto.SBProtoMessages.ProtoVersionRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * required string version = 2;
 * @return {string}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.getVersion=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
 */proto.SBProtoMessages.ProtoVersionRes.prototype.setVersion=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
 */proto.SBProtoMessages.ProtoVersionRes.prototype.clearVersion=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.hasVersion=function(){return jspb.Message.getField(this,2)!=null;};/**
 * repeated PluginInfo pluginInfo = 3;
 * @return {!Array<!proto.SBProtoMessages.PluginInfo>}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.getPlugininfoList=function(){return(/** @type{!Array<!proto.SBProtoMessages.PluginInfo>} */jspb.Message.getRepeatedWrapperField(this,QXCommonModel_pb.PluginInfo,3));};/**
 * @param {!Array<!proto.SBProtoMessages.PluginInfo>} value
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
*/proto.SBProtoMessages.ProtoVersionRes.prototype.setPlugininfoList=function(value){return jspb.Message.setRepeatedWrapperField(this,3,value);};/**
 * @param {!proto.SBProtoMessages.PluginInfo=} opt_value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.PluginInfo}
 */proto.SBProtoMessages.ProtoVersionRes.prototype.addPlugininfo=function(opt_value,opt_index){return jspb.Message.addToRepeatedWrapperField(this,3,opt_value,proto.SBProtoMessages.PluginInfo,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.ProtoVersionRes} returns this
 */proto.SBProtoMessages.ProtoVersionRes.prototype.clearPlugininfoList=function(){return this.setPlugininfoList([]);};goog.object.extend(exports,proto.SBProtoMessages);