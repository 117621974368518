"use strict";// source: PortfolioOptimizationModel.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);goog.exportSymbol('proto.SBProtoMessages.Calculations',null,global);goog.exportSymbol('proto.SBProtoMessages.Funds',null,global);goog.exportSymbol('proto.SBProtoMessages.IndexPerformance',null,global);goog.exportSymbol('proto.SBProtoMessages.MonteCarloSimulation',null,global);goog.exportSymbol('proto.SBProtoMessages.OptimizedPosition',null,global);goog.exportSymbol('proto.SBProtoMessages.OptimizedPositions',null,global);goog.exportSymbol('proto.SBProtoMessages.Percentiles',null,global);goog.exportSymbol('proto.SBProtoMessages.Performance',null,global);goog.exportSymbol('proto.SBProtoMessages.PortfolioPerformance',null,global);goog.exportSymbol('proto.SBProtoMessages.PositionInfo',null,global);goog.exportSymbol('proto.SBProtoMessages.RemainingFunds',null,global);goog.exportSymbol('proto.SBProtoMessages.Weights',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.PositionInfo=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.PositionInfo,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.PositionInfo.displayName='proto.SBProtoMessages.PositionInfo';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.Funds=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.Funds,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.Funds.displayName='proto.SBProtoMessages.Funds';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.Weights=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.Weights,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.Weights.displayName='proto.SBProtoMessages.Weights';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.IndexPerformance=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.IndexPerformance,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.IndexPerformance.displayName='proto.SBProtoMessages.IndexPerformance';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.Calculations=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.Calculations,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.Calculations.displayName='proto.SBProtoMessages.Calculations';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.MonteCarloSimulation=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.MonteCarloSimulation,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.MonteCarloSimulation.displayName='proto.SBProtoMessages.MonteCarloSimulation';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.Percentiles=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,proto.SBProtoMessages.Percentiles.repeatedFields_,null);};goog.inherits(proto.SBProtoMessages.Percentiles,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.Percentiles.displayName='proto.SBProtoMessages.Percentiles';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.OptimizedPositions=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.OptimizedPositions,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.OptimizedPositions.displayName='proto.SBProtoMessages.OptimizedPositions';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.OptimizedPosition=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.OptimizedPosition,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.OptimizedPosition.displayName='proto.SBProtoMessages.OptimizedPosition';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.RemainingFunds=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.RemainingFunds,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.RemainingFunds.displayName='proto.SBProtoMessages.RemainingFunds';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.PortfolioPerformance=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.PortfolioPerformance,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.PortfolioPerformance.displayName='proto.SBProtoMessages.PortfolioPerformance';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.Performance=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.Performance,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.Performance.displayName='proto.SBProtoMessages.Performance';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.PositionInfo.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.PositionInfo.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.PositionInfo} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.PositionInfo.toObject=function(includeInstance,msg){var f,obj={quantity:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,currency:(f=jspb.Message.getField(msg,2))==null?undefined:f,price:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.PositionInfo}
 */proto.SBProtoMessages.PositionInfo.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.PositionInfo();return proto.SBProtoMessages.PositionInfo.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.PositionInfo} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.PositionInfo}
 */proto.SBProtoMessages.PositionInfo.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setQuantity(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setCurrency(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setPrice(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.PositionInfo.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.PositionInfo.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.PositionInfo} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.PositionInfo.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}};/**
 * optional double quantity = 1;
 * @return {number}
 */proto.SBProtoMessages.PositionInfo.prototype.getQuantity=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.setQuantity=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.clearQuantity=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.PositionInfo.prototype.hasQuantity=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional string currency = 2;
 * @return {string}
 */proto.SBProtoMessages.PositionInfo.prototype.getCurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.setCurrency=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.clearCurrency=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.PositionInfo.prototype.hasCurrency=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double price = 3;
 * @return {number}
 */proto.SBProtoMessages.PositionInfo.prototype.getPrice=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.setPrice=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.PositionInfo} returns this
 */proto.SBProtoMessages.PositionInfo.prototype.clearPrice=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.PositionInfo.prototype.hasPrice=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.Funds.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.Funds.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.Funds} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.Funds.toObject=function(includeInstance,msg){var f,obj={currency:(f=jspb.Message.getField(msg,1))==null?undefined:f,value:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.Funds}
 */proto.SBProtoMessages.Funds.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.Funds();return proto.SBProtoMessages.Funds.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.Funds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.Funds}
 */proto.SBProtoMessages.Funds.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {string} */reader.readString();msg.setCurrency(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setValue(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.Funds.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.Funds.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.Funds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.Funds.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {string} */jspb.Message.getField(message,1);if(f!=null){writer.writeString(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}};/**
 * optional string currency = 1;
 * @return {string}
 */proto.SBProtoMessages.Funds.prototype.getCurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,1,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.Funds} returns this
 */proto.SBProtoMessages.Funds.prototype.setCurrency=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Funds} returns this
 */proto.SBProtoMessages.Funds.prototype.clearCurrency=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Funds.prototype.hasCurrency=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double value = 2;
 * @return {number}
 */proto.SBProtoMessages.Funds.prototype.getValue=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Funds} returns this
 */proto.SBProtoMessages.Funds.prototype.setValue=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Funds} returns this
 */proto.SBProtoMessages.Funds.prototype.clearValue=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Funds.prototype.hasValue=function(){return jspb.Message.getField(this,2)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.Weights.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.Weights.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.Weights} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.Weights.toObject=function(includeInstance,msg){var f,obj={min:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,max:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.Weights}
 */proto.SBProtoMessages.Weights.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.Weights();return proto.SBProtoMessages.Weights.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.Weights} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.Weights}
 */proto.SBProtoMessages.Weights.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setMin(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setMax(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.Weights.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.Weights.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.Weights} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.Weights.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}};/**
 * optional double min = 1;
 * @return {number}
 */proto.SBProtoMessages.Weights.prototype.getMin=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Weights} returns this
 */proto.SBProtoMessages.Weights.prototype.setMin=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Weights} returns this
 */proto.SBProtoMessages.Weights.prototype.clearMin=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Weights.prototype.hasMin=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double max = 2;
 * @return {number}
 */proto.SBProtoMessages.Weights.prototype.getMax=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Weights} returns this
 */proto.SBProtoMessages.Weights.prototype.setMax=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Weights} returns this
 */proto.SBProtoMessages.Weights.prototype.clearMax=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Weights.prototype.hasMax=function(){return jspb.Message.getField(this,2)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.IndexPerformance.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.IndexPerformance.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.IndexPerformance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.IndexPerformance.toObject=function(includeInstance,msg){var f,obj={calculations:(f=msg.getCalculations())&&proto.SBProtoMessages.Calculations.toObject(includeInstance,f),dailyvaluesMap:(f=msg.getDailyvaluesMap())?f.toObject(includeInstance,undefined):[],index:(f=jspb.Message.getField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.IndexPerformance}
 */proto.SBProtoMessages.IndexPerformance.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.IndexPerformance();return proto.SBProtoMessages.IndexPerformance.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.IndexPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.IndexPerformance}
 */proto.SBProtoMessages.IndexPerformance.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=new proto.SBProtoMessages.Calculations();reader.readMessage(value,proto.SBProtoMessages.Calculations.deserializeBinaryFromReader);msg.setCalculations(value);break;case 2:var value=msg.getDailyvaluesMap();reader.readMessage(value,function(message,reader){jspb.Map.deserializeBinary(message,reader,jspb.BinaryReader.prototype.readUint64,jspb.BinaryReader.prototype.readDouble,null,0,0.0);});break;case 3:var value=/** @type {string} */reader.readString();msg.setIndex(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.IndexPerformance.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.IndexPerformance.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.IndexPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.IndexPerformance.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getCalculations();if(f!=null){writer.writeMessage(1,f,proto.SBProtoMessages.Calculations.serializeBinaryToWriter);}f=message.getDailyvaluesMap(true);if(f&&f.getLength()>0){f.serializeBinary(2,writer,jspb.BinaryWriter.prototype.writeUint64,jspb.BinaryWriter.prototype.writeDouble);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}};/**
 * optional Calculations calculations = 1;
 * @return {?proto.SBProtoMessages.Calculations}
 */proto.SBProtoMessages.IndexPerformance.prototype.getCalculations=function(){return(/** @type{?proto.SBProtoMessages.Calculations} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Calculations,1));};/**
 * @param {?proto.SBProtoMessages.Calculations|undefined} value
 * @return {!proto.SBProtoMessages.IndexPerformance} returns this
*/proto.SBProtoMessages.IndexPerformance.prototype.setCalculations=function(value){return jspb.Message.setWrapperField(this,1,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.IndexPerformance} returns this
 */proto.SBProtoMessages.IndexPerformance.prototype.clearCalculations=function(){return this.setCalculations(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.IndexPerformance.prototype.hasCalculations=function(){return jspb.Message.getField(this,1)!=null;};/**
 * map<uint64, double> dailyValues = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */proto.SBProtoMessages.IndexPerformance.prototype.getDailyvaluesMap=function(opt_noLazyCreate){return(/** @type {!jspb.Map<number,number>} */jspb.Message.getMapField(this,2,opt_noLazyCreate,null));};/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SBProtoMessages.IndexPerformance} returns this
 */proto.SBProtoMessages.IndexPerformance.prototype.clearDailyvaluesMap=function(){this.getDailyvaluesMap().clear();return this;};/**
 * optional string index = 3;
 * @return {string}
 */proto.SBProtoMessages.IndexPerformance.prototype.getIndex=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.IndexPerformance} returns this
 */proto.SBProtoMessages.IndexPerformance.prototype.setIndex=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.IndexPerformance} returns this
 */proto.SBProtoMessages.IndexPerformance.prototype.clearIndex=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.IndexPerformance.prototype.hasIndex=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.Calculations.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.Calculations.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.Calculations} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.Calculations.toObject=function(includeInstance,msg){var f,obj={annualizedreturn:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,maxdrawdown:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f,volatility:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.Calculations}
 */proto.SBProtoMessages.Calculations.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.Calculations();return proto.SBProtoMessages.Calculations.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.Calculations} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.Calculations}
 */proto.SBProtoMessages.Calculations.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setAnnualizedreturn(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setMaxdrawdown(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setVolatility(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.Calculations.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.Calculations.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.Calculations} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.Calculations.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}};/**
 * optional double annualizedReturn = 1;
 * @return {number}
 */proto.SBProtoMessages.Calculations.prototype.getAnnualizedreturn=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.setAnnualizedreturn=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.clearAnnualizedreturn=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Calculations.prototype.hasAnnualizedreturn=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double maxDrawdown = 2;
 * @return {number}
 */proto.SBProtoMessages.Calculations.prototype.getMaxdrawdown=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.setMaxdrawdown=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.clearMaxdrawdown=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Calculations.prototype.hasMaxdrawdown=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double volatility = 3;
 * @return {number}
 */proto.SBProtoMessages.Calculations.prototype.getVolatility=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.setVolatility=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.Calculations} returns this
 */proto.SBProtoMessages.Calculations.prototype.clearVolatility=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Calculations.prototype.hasVolatility=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.MonteCarloSimulation.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.MonteCarloSimulation.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.MonteCarloSimulation} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.MonteCarloSimulation.toObject=function(includeInstance,msg){var f,obj={optimizedportfolio:(f=msg.getOptimizedportfolio())&&proto.SBProtoMessages.Percentiles.toObject(includeInstance,f),preoptimization:(f=msg.getPreoptimization())&&proto.SBProtoMessages.Percentiles.toObject(includeInstance,f)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.MonteCarloSimulation}
 */proto.SBProtoMessages.MonteCarloSimulation.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.MonteCarloSimulation();return proto.SBProtoMessages.MonteCarloSimulation.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.MonteCarloSimulation} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.MonteCarloSimulation}
 */proto.SBProtoMessages.MonteCarloSimulation.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=new proto.SBProtoMessages.Percentiles();reader.readMessage(value,proto.SBProtoMessages.Percentiles.deserializeBinaryFromReader);msg.setOptimizedportfolio(value);break;case 2:var value=new proto.SBProtoMessages.Percentiles();reader.readMessage(value,proto.SBProtoMessages.Percentiles.deserializeBinaryFromReader);msg.setPreoptimization(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.MonteCarloSimulation.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.MonteCarloSimulation} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.MonteCarloSimulation.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getOptimizedportfolio();if(f!=null){writer.writeMessage(1,f,proto.SBProtoMessages.Percentiles.serializeBinaryToWriter);}f=message.getPreoptimization();if(f!=null){writer.writeMessage(2,f,proto.SBProtoMessages.Percentiles.serializeBinaryToWriter);}};/**
 * optional Percentiles optimizedPortfolio = 1;
 * @return {?proto.SBProtoMessages.Percentiles}
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.getOptimizedportfolio=function(){return(/** @type{?proto.SBProtoMessages.Percentiles} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Percentiles,1));};/**
 * @param {?proto.SBProtoMessages.Percentiles|undefined} value
 * @return {!proto.SBProtoMessages.MonteCarloSimulation} returns this
*/proto.SBProtoMessages.MonteCarloSimulation.prototype.setOptimizedportfolio=function(value){return jspb.Message.setWrapperField(this,1,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.MonteCarloSimulation} returns this
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.clearOptimizedportfolio=function(){return this.setOptimizedportfolio(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.hasOptimizedportfolio=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional Percentiles preOptimization = 2;
 * @return {?proto.SBProtoMessages.Percentiles}
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.getPreoptimization=function(){return(/** @type{?proto.SBProtoMessages.Percentiles} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Percentiles,2));};/**
 * @param {?proto.SBProtoMessages.Percentiles|undefined} value
 * @return {!proto.SBProtoMessages.MonteCarloSimulation} returns this
*/proto.SBProtoMessages.MonteCarloSimulation.prototype.setPreoptimization=function(value){return jspb.Message.setWrapperField(this,2,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.MonteCarloSimulation} returns this
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.clearPreoptimization=function(){return this.setPreoptimization(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.MonteCarloSimulation.prototype.hasPreoptimization=function(){return jspb.Message.getField(this,2)!=null;};/**
 * List of repeated fields within this message type.
 * @private {!Array<number>}
 * @const
 */proto.SBProtoMessages.Percentiles.repeatedFields_=[1,2,3,4,5];if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.Percentiles.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.Percentiles.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.Percentiles} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.Percentiles.toObject=function(includeInstance,msg){var f,obj={percentile10List:(f=jspb.Message.getRepeatedFloatingPointField(msg,1))==null?undefined:f,percentile25List:(f=jspb.Message.getRepeatedFloatingPointField(msg,2))==null?undefined:f,percentile50List:(f=jspb.Message.getRepeatedFloatingPointField(msg,3))==null?undefined:f,percentile75List:(f=jspb.Message.getRepeatedFloatingPointField(msg,4))==null?undefined:f,percentile90List:(f=jspb.Message.getRepeatedFloatingPointField(msg,5))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.Percentiles}
 */proto.SBProtoMessages.Percentiles.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.Percentiles();return proto.SBProtoMessages.Percentiles.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.Percentiles} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.Percentiles}
 */proto.SBProtoMessages.Percentiles.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.addPercentile10(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.addPercentile25(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.addPercentile50(value);break;case 4:var value=/** @type {number} */reader.readDouble();msg.addPercentile75(value);break;case 5:var value=/** @type {number} */reader.readDouble();msg.addPercentile90(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.Percentiles.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.Percentiles.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.Percentiles} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.Percentiles.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getPercentile10List();if(f.length>0){writer.writeRepeatedDouble(1,f);}f=message.getPercentile25List();if(f.length>0){writer.writeRepeatedDouble(2,f);}f=message.getPercentile50List();if(f.length>0){writer.writeRepeatedDouble(3,f);}f=message.getPercentile75List();if(f.length>0){writer.writeRepeatedDouble(4,f);}f=message.getPercentile90List();if(f.length>0){writer.writeRepeatedDouble(5,f);}};/**
 * repeated double percentile10 = 1;
 * @return {!Array<number>}
 */proto.SBProtoMessages.Percentiles.prototype.getPercentile10List=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedFloatingPointField(this,1));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.setPercentile10List=function(value){return jspb.Message.setField(this,1,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.addPercentile10=function(value,opt_index){return jspb.Message.addToRepeatedField(this,1,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.clearPercentile10List=function(){return this.setPercentile10List([]);};/**
 * repeated double percentile25 = 2;
 * @return {!Array<number>}
 */proto.SBProtoMessages.Percentiles.prototype.getPercentile25List=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedFloatingPointField(this,2));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.setPercentile25List=function(value){return jspb.Message.setField(this,2,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.addPercentile25=function(value,opt_index){return jspb.Message.addToRepeatedField(this,2,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.clearPercentile25List=function(){return this.setPercentile25List([]);};/**
 * repeated double percentile50 = 3;
 * @return {!Array<number>}
 */proto.SBProtoMessages.Percentiles.prototype.getPercentile50List=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedFloatingPointField(this,3));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.setPercentile50List=function(value){return jspb.Message.setField(this,3,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.addPercentile50=function(value,opt_index){return jspb.Message.addToRepeatedField(this,3,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.clearPercentile50List=function(){return this.setPercentile50List([]);};/**
 * repeated double percentile75 = 4;
 * @return {!Array<number>}
 */proto.SBProtoMessages.Percentiles.prototype.getPercentile75List=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedFloatingPointField(this,4));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.setPercentile75List=function(value){return jspb.Message.setField(this,4,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.addPercentile75=function(value,opt_index){return jspb.Message.addToRepeatedField(this,4,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.clearPercentile75List=function(){return this.setPercentile75List([]);};/**
 * repeated double percentile90 = 5;
 * @return {!Array<number>}
 */proto.SBProtoMessages.Percentiles.prototype.getPercentile90List=function(){return(/** @type {!Array<number>} */jspb.Message.getRepeatedFloatingPointField(this,5));};/**
 * @param {!Array<number>} value
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.setPercentile90List=function(value){return jspb.Message.setField(this,5,value||[]);};/**
 * @param {number} value
 * @param {number=} opt_index
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.addPercentile90=function(value,opt_index){return jspb.Message.addToRepeatedField(this,5,value,opt_index);};/**
 * Clears the list making it empty but non-null.
 * @return {!proto.SBProtoMessages.Percentiles} returns this
 */proto.SBProtoMessages.Percentiles.prototype.clearPercentile90List=function(){return this.setPercentile90List([]);};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.OptimizedPositions.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.OptimizedPositions.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.OptimizedPositions} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.OptimizedPositions.toObject=function(includeInstance,msg){var f,obj={positionsMap:(f=msg.getPositionsMap())?f.toObject(includeInstance,proto.SBProtoMessages.OptimizedPosition.toObject):[],remainingfunds:(f=msg.getRemainingfunds())&&proto.SBProtoMessages.RemainingFunds.toObject(includeInstance,f),excludedsymbolslist:(f=jspb.Message.getField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.OptimizedPositions}
 */proto.SBProtoMessages.OptimizedPositions.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.OptimizedPositions();return proto.SBProtoMessages.OptimizedPositions.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.OptimizedPositions} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.OptimizedPositions}
 */proto.SBProtoMessages.OptimizedPositions.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=msg.getPositionsMap();reader.readMessage(value,function(message,reader){jspb.Map.deserializeBinary(message,reader,jspb.BinaryReader.prototype.readString,jspb.BinaryReader.prototype.readMessage,proto.SBProtoMessages.OptimizedPosition.deserializeBinaryFromReader,"",new proto.SBProtoMessages.OptimizedPosition());});break;case 2:var value=new proto.SBProtoMessages.RemainingFunds();reader.readMessage(value,proto.SBProtoMessages.RemainingFunds.deserializeBinaryFromReader);msg.setRemainingfunds(value);break;case 3:var value=/** @type {string} */reader.readString();msg.setExcludedsymbolslist(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.OptimizedPositions.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.OptimizedPositions.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.OptimizedPositions} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.OptimizedPositions.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getPositionsMap(true);if(f&&f.getLength()>0){f.serializeBinary(1,writer,jspb.BinaryWriter.prototype.writeString,jspb.BinaryWriter.prototype.writeMessage,proto.SBProtoMessages.OptimizedPosition.serializeBinaryToWriter);}f=message.getRemainingfunds();if(f!=null){writer.writeMessage(2,f,proto.SBProtoMessages.RemainingFunds.serializeBinaryToWriter);}f=/** @type {string} */jspb.Message.getField(message,3);if(f!=null){writer.writeString(3,f);}};/**
 * map<string, OptimizedPosition> positions = 1;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<string,!proto.SBProtoMessages.OptimizedPosition>}
 */proto.SBProtoMessages.OptimizedPositions.prototype.getPositionsMap=function(opt_noLazyCreate){return(/** @type {!jspb.Map<string,!proto.SBProtoMessages.OptimizedPosition>} */jspb.Message.getMapField(this,1,opt_noLazyCreate,proto.SBProtoMessages.OptimizedPosition));};/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SBProtoMessages.OptimizedPositions} returns this
 */proto.SBProtoMessages.OptimizedPositions.prototype.clearPositionsMap=function(){this.getPositionsMap().clear();return this;};/**
 * optional RemainingFunds remainingFunds = 2;
 * @return {?proto.SBProtoMessages.RemainingFunds}
 */proto.SBProtoMessages.OptimizedPositions.prototype.getRemainingfunds=function(){return(/** @type{?proto.SBProtoMessages.RemainingFunds} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.RemainingFunds,2));};/**
 * @param {?proto.SBProtoMessages.RemainingFunds|undefined} value
 * @return {!proto.SBProtoMessages.OptimizedPositions} returns this
*/proto.SBProtoMessages.OptimizedPositions.prototype.setRemainingfunds=function(value){return jspb.Message.setWrapperField(this,2,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.OptimizedPositions} returns this
 */proto.SBProtoMessages.OptimizedPositions.prototype.clearRemainingfunds=function(){return this.setRemainingfunds(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.OptimizedPositions.prototype.hasRemainingfunds=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional string excludedSymbolsList = 3;
 * @return {string}
 */proto.SBProtoMessages.OptimizedPositions.prototype.getExcludedsymbolslist=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,3,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.OptimizedPositions} returns this
 */proto.SBProtoMessages.OptimizedPositions.prototype.setExcludedsymbolslist=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.OptimizedPositions} returns this
 */proto.SBProtoMessages.OptimizedPositions.prototype.clearExcludedsymbolslist=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.OptimizedPositions.prototype.hasExcludedsymbolslist=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.OptimizedPosition.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.OptimizedPosition.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.OptimizedPosition} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.OptimizedPosition.toObject=function(includeInstance,msg){var f,obj={actualweight:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,optimizedquantity:(f=jspb.Message.getOptionalFloatingPointField(msg,2))==null?undefined:f,targetweight:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.OptimizedPosition}
 */proto.SBProtoMessages.OptimizedPosition.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.OptimizedPosition();return proto.SBProtoMessages.OptimizedPosition.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.OptimizedPosition} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.OptimizedPosition}
 */proto.SBProtoMessages.OptimizedPosition.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setActualweight(value);break;case 2:var value=/** @type {number} */reader.readDouble();msg.setOptimizedquantity(value);break;case 3:var value=/** @type {number} */reader.readDouble();msg.setTargetweight(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.OptimizedPosition.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.OptimizedPosition.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.OptimizedPosition} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.OptimizedPosition.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeDouble(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeDouble(3,f);}};/**
 * optional double actualWeight = 1;
 * @return {number}
 */proto.SBProtoMessages.OptimizedPosition.prototype.getActualweight=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.setActualweight=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.clearActualweight=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.OptimizedPosition.prototype.hasActualweight=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional double optimizedQuantity = 2;
 * @return {number}
 */proto.SBProtoMessages.OptimizedPosition.prototype.getOptimizedquantity=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,2,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.setOptimizedquantity=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.clearOptimizedquantity=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.OptimizedPosition.prototype.hasOptimizedquantity=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional double targetWeight = 3;
 * @return {number}
 */proto.SBProtoMessages.OptimizedPosition.prototype.getTargetweight=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.setTargetweight=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.OptimizedPosition} returns this
 */proto.SBProtoMessages.OptimizedPosition.prototype.clearTargetweight=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.OptimizedPosition.prototype.hasTargetweight=function(){return jspb.Message.getField(this,3)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.RemainingFunds.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.RemainingFunds.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.RemainingFunds} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.RemainingFunds.toObject=function(includeInstance,msg){var f,obj={actualweight:(f=jspb.Message.getOptionalFloatingPointField(msg,1))==null?undefined:f,currency:(f=jspb.Message.getField(msg,2))==null?undefined:f,quantity:(f=jspb.Message.getOptionalFloatingPointField(msg,3))==null?undefined:f,targetweight:(f=jspb.Message.getOptionalFloatingPointField(msg,4))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.RemainingFunds}
 */proto.SBProtoMessages.RemainingFunds.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.RemainingFunds();return proto.SBProtoMessages.RemainingFunds.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.RemainingFunds} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.RemainingFunds}
 */proto.SBProtoMessages.RemainingFunds.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {number} */reader.readDouble();msg.setActualweight(value);break;case 2:var value=/** @type {string} */reader.readString();msg.setCurrency(value);break;case 3:var value=/** @type {number} */reader.readFloat();msg.setQuantity(value);break;case 4:var value=/** @type {number} */reader.readDouble();msg.setTargetweight(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.RemainingFunds.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.RemainingFunds.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.RemainingFunds} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.RemainingFunds.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {number} */jspb.Message.getField(message,1);if(f!=null){writer.writeDouble(1,f);}f=/** @type {string} */jspb.Message.getField(message,2);if(f!=null){writer.writeString(2,f);}f=/** @type {number} */jspb.Message.getField(message,3);if(f!=null){writer.writeFloat(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeDouble(4,f);}};/**
 * optional double actualWeight = 1;
 * @return {number}
 */proto.SBProtoMessages.RemainingFunds.prototype.getActualweight=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,1,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.setActualweight=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.clearActualweight=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.RemainingFunds.prototype.hasActualweight=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional string currency = 2;
 * @return {string}
 */proto.SBProtoMessages.RemainingFunds.prototype.getCurrency=function(){return(/** @type {string} */jspb.Message.getFieldWithDefault(this,2,""));};/**
 * @param {string} value
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.setCurrency=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.clearCurrency=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.RemainingFunds.prototype.hasCurrency=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional float quantity = 3;
 * @return {number}
 */proto.SBProtoMessages.RemainingFunds.prototype.getQuantity=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,3,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.setQuantity=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.clearQuantity=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.RemainingFunds.prototype.hasQuantity=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional double targetWeight = 4;
 * @return {number}
 */proto.SBProtoMessages.RemainingFunds.prototype.getTargetweight=function(){return(/** @type {number} */jspb.Message.getFloatingPointFieldWithDefault(this,4,0.0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.setTargetweight=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.RemainingFunds} returns this
 */proto.SBProtoMessages.RemainingFunds.prototype.clearTargetweight=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.RemainingFunds.prototype.hasTargetweight=function(){return jspb.Message.getField(this,4)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.PortfolioPerformance.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.PortfolioPerformance.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.PortfolioPerformance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.PortfolioPerformance.toObject=function(includeInstance,msg){var f,obj={optimized:(f=msg.getOptimized())&&proto.SBProtoMessages.Performance.toObject(includeInstance,f),preoptimization:(f=msg.getPreoptimization())&&proto.SBProtoMessages.Performance.toObject(includeInstance,f)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.PortfolioPerformance}
 */proto.SBProtoMessages.PortfolioPerformance.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.PortfolioPerformance();return proto.SBProtoMessages.PortfolioPerformance.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.PortfolioPerformance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.PortfolioPerformance}
 */proto.SBProtoMessages.PortfolioPerformance.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=new proto.SBProtoMessages.Performance();reader.readMessage(value,proto.SBProtoMessages.Performance.deserializeBinaryFromReader);msg.setOptimized(value);break;case 2:var value=new proto.SBProtoMessages.Performance();reader.readMessage(value,proto.SBProtoMessages.Performance.deserializeBinaryFromReader);msg.setPreoptimization(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.PortfolioPerformance.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.PortfolioPerformance.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.PortfolioPerformance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.PortfolioPerformance.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getOptimized();if(f!=null){writer.writeMessage(1,f,proto.SBProtoMessages.Performance.serializeBinaryToWriter);}f=message.getPreoptimization();if(f!=null){writer.writeMessage(2,f,proto.SBProtoMessages.Performance.serializeBinaryToWriter);}};/**
 * optional Performance optimized = 1;
 * @return {?proto.SBProtoMessages.Performance}
 */proto.SBProtoMessages.PortfolioPerformance.prototype.getOptimized=function(){return(/** @type{?proto.SBProtoMessages.Performance} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Performance,1));};/**
 * @param {?proto.SBProtoMessages.Performance|undefined} value
 * @return {!proto.SBProtoMessages.PortfolioPerformance} returns this
*/proto.SBProtoMessages.PortfolioPerformance.prototype.setOptimized=function(value){return jspb.Message.setWrapperField(this,1,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.PortfolioPerformance} returns this
 */proto.SBProtoMessages.PortfolioPerformance.prototype.clearOptimized=function(){return this.setOptimized(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.PortfolioPerformance.prototype.hasOptimized=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional Performance preOptimization = 2;
 * @return {?proto.SBProtoMessages.Performance}
 */proto.SBProtoMessages.PortfolioPerformance.prototype.getPreoptimization=function(){return(/** @type{?proto.SBProtoMessages.Performance} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Performance,2));};/**
 * @param {?proto.SBProtoMessages.Performance|undefined} value
 * @return {!proto.SBProtoMessages.PortfolioPerformance} returns this
*/proto.SBProtoMessages.PortfolioPerformance.prototype.setPreoptimization=function(value){return jspb.Message.setWrapperField(this,2,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.PortfolioPerformance} returns this
 */proto.SBProtoMessages.PortfolioPerformance.prototype.clearPreoptimization=function(){return this.setPreoptimization(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.PortfolioPerformance.prototype.hasPreoptimization=function(){return jspb.Message.getField(this,2)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.Performance.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.Performance.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.Performance} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.Performance.toObject=function(includeInstance,msg){var f,obj={calculations:(f=msg.getCalculations())&&proto.SBProtoMessages.Calculations.toObject(includeInstance,f),dailyvaluesMap:(f=msg.getDailyvaluesMap())?f.toObject(includeInstance,undefined):[]};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.Performance}
 */proto.SBProtoMessages.Performance.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.Performance();return proto.SBProtoMessages.Performance.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.Performance} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.Performance}
 */proto.SBProtoMessages.Performance.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=new proto.SBProtoMessages.Calculations();reader.readMessage(value,proto.SBProtoMessages.Calculations.deserializeBinaryFromReader);msg.setCalculations(value);break;case 2:var value=msg.getDailyvaluesMap();reader.readMessage(value,function(message,reader){jspb.Map.deserializeBinary(message,reader,jspb.BinaryReader.prototype.readUint64,jspb.BinaryReader.prototype.readDouble,null,0,0.0);});break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.Performance.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.Performance.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.Performance} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.Performance.serializeBinaryToWriter=function(message,writer){var f=undefined;f=message.getCalculations();if(f!=null){writer.writeMessage(1,f,proto.SBProtoMessages.Calculations.serializeBinaryToWriter);}f=message.getDailyvaluesMap(true);if(f&&f.getLength()>0){f.serializeBinary(2,writer,jspb.BinaryWriter.prototype.writeUint64,jspb.BinaryWriter.prototype.writeDouble);}};/**
 * optional Calculations calculations = 1;
 * @return {?proto.SBProtoMessages.Calculations}
 */proto.SBProtoMessages.Performance.prototype.getCalculations=function(){return(/** @type{?proto.SBProtoMessages.Calculations} */jspb.Message.getWrapperField(this,proto.SBProtoMessages.Calculations,1));};/**
 * @param {?proto.SBProtoMessages.Calculations|undefined} value
 * @return {!proto.SBProtoMessages.Performance} returns this
*/proto.SBProtoMessages.Performance.prototype.setCalculations=function(value){return jspb.Message.setWrapperField(this,1,value);};/**
 * Clears the message field making it undefined.
 * @return {!proto.SBProtoMessages.Performance} returns this
 */proto.SBProtoMessages.Performance.prototype.clearCalculations=function(){return this.setCalculations(undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.Performance.prototype.hasCalculations=function(){return jspb.Message.getField(this,1)!=null;};/**
 * map<uint64, double> dailyValues = 2;
 * @param {boolean=} opt_noLazyCreate Do not create the map if
 * empty, instead returning `undefined`
 * @return {!jspb.Map<number,number>}
 */proto.SBProtoMessages.Performance.prototype.getDailyvaluesMap=function(opt_noLazyCreate){return(/** @type {!jspb.Map<number,number>} */jspb.Message.getMapField(this,2,opt_noLazyCreate,null));};/**
 * Clears values from the map. The map will be non-null.
 * @return {!proto.SBProtoMessages.Performance} returns this
 */proto.SBProtoMessages.Performance.prototype.clearDailyvaluesMap=function(){this.getDailyvaluesMap().clear();return this;};goog.object.extend(exports,proto.SBProtoMessages);