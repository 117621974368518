"use strict";// source: CampaignsMessages.proto
/**
 * @fileoverview
 * @enhanceable
 * @suppress {messageConventions} JS Compiler reports an error if a variable or
 *     field starts with 'MSG_' and isn't a translatable message.
 * @public
 */ // GENERATED CODE -- DO NOT EDIT!
var jspb=require('google-protobuf');var goog=jspb;var global=Function('return this')();var QXCommonModel_pb=require('./QXCommonModel_pb.js');goog.object.extend(proto,QXCommonModel_pb);goog.exportSymbol('proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoFreeTradesCounterReq',null,global);goog.exportSymbol('proto.SBProtoMessages.ProtoFreeTradesCounterRes',null,global);/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoFreeTradesCounterReq,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoFreeTradesCounterReq.displayName='proto.SBProtoMessages.ProtoFreeTradesCounterReq';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoFreeTradesCounterRes,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoFreeTradesCounterRes.displayName='proto.SBProtoMessages.ProtoFreeTradesCounterRes';}/**
 * Generated by JsPbCodeGenerator.
 * @param {Array=} opt_data Optional initial data array, typically from a
 * server response, or constructed directly in Javascript. The array is used
 * in place and becomes part of the constructed object. It is not cloned.
 * If no data is provided, the constructed object will be empty, but still
 * valid.
 * @extends {jspb.Message}
 * @constructor
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent=function(opt_data){jspb.Message.initialize(this,opt_data,0,-1,null,null);};goog.inherits(proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent,jspb.Message);if(goog.DEBUG&&!COMPILED){/**
     * @public
     * @override
     */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.displayName='proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent';}if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoFreeTradesCounterReq.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterReq} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoFreeTradesCounterReq.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,2100)};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterReq}
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoFreeTradesCounterReq();return proto.SBProtoMessages.ProtoFreeTradesCounterReq.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterReq} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterReq}
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoFreeTradesCounterReq.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterReq} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,2100));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterReq} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterReq} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterReq.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoFreeTradesCounterRes.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoFreeTradesCounterRes.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,2101),freetrades:(f=jspb.Message.getField(msg,2))==null?undefined:f,infinitytrades:(f=jspb.Message.getBooleanField(msg,3))==null?undefined:f,untilutctimestamp:(f=jspb.Message.getField(msg,4))==null?undefined:f,infinitytimestamp:(f=jspb.Message.getBooleanField(msg,5))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoFreeTradesCounterRes();return proto.SBProtoMessages.ProtoFreeTradesCounterRes.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=/** @type {number} */reader.readUint32();msg.setFreetrades(value);break;case 3:var value=/** @type {boolean} */reader.readBool();msg.setInfinitytrades(value);break;case 4:var value=/** @type {number} */reader.readUint64();msg.setUntilutctimestamp(value);break;case 5:var value=/** @type {boolean} */reader.readBool();msg.setInfinitytimestamp(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoFreeTradesCounterRes.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeUint32(2,f);}f=/** @type {boolean} */jspb.Message.getField(message,3);if(f!=null){writer.writeBool(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeUint64(4,f);}f=/** @type {boolean} */jspb.Message.getField(message,5);if(f!=null){writer.writeBool(5,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,2101));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional uint32 FreeTrades = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.getFreetrades=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.setFreetrades=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.clearFreetrades=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.hasFreetrades=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional bool InfinityTrades = 3;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.getInfinitytrades=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,3,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.setInfinitytrades=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.clearInfinitytrades=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.hasInfinitytrades=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional uint64 UntilUtcTimestamp = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.getUntilutctimestamp=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.setUntilutctimestamp=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.clearUntilutctimestamp=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.hasUntilutctimestamp=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional bool InfinityTimestamp = 5;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.getInfinitytimestamp=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,5,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.setInfinitytimestamp=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterRes} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.clearInfinitytimestamp=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterRes.prototype.hasInfinitytimestamp=function(){return jspb.Message.getField(this,5)!=null;};if(jspb.Message.GENERATE_TO_OBJECT){/**
     * Creates an object representation of this proto.
     * Field names that are reserved in JavaScript and will be renamed to pb_name.
     * Optional fields that are not set will be set to undefined.
     * To access a reserved field use, foo.pb_<name>, eg, foo.pb_default.
     * For the list of reserved names please see:
     *     net/proto2/compiler/js/internal/generator.cc#kKeyword.
     * @param {boolean=} opt_includeInstance Deprecated. whether to include the
     *     JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @return {!Object}
     */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.toObject=function(opt_includeInstance){return proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.toObject(opt_includeInstance,this);};/**
     * Static version of the {@see toObject} method.
     * @param {boolean|undefined} includeInstance Deprecated. Whether to include
     *     the JSPB instance for transitional soy proto support:
     *     http://goto/soy-param-migration
     * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} msg The msg instance to transform.
     * @return {!Object}
     * @suppress {unusedLocalVariables} f is only used for nested messages
     */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.toObject=function(includeInstance,msg){var f,obj={payloadtype:jspb.Message.getFieldWithDefault(msg,1,2102),freetrades:(f=jspb.Message.getField(msg,2))==null?undefined:f,infinitytrades:(f=jspb.Message.getBooleanField(msg,3))==null?undefined:f,untilutctimestamp:(f=jspb.Message.getField(msg,4))==null?undefined:f,infinitytimestamp:(f=jspb.Message.getBooleanField(msg,5))==null?undefined:f};if(includeInstance){obj.$jspbMessageInstance=msg;}return obj;};}/**
 * Deserializes binary data (in protobuf wire format).
 * @param {jspb.ByteSource} bytes The bytes to deserialize.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.deserializeBinary=function(bytes){var reader=new jspb.BinaryReader(bytes);var msg=new proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent();return proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.deserializeBinaryFromReader(msg,reader);};/**
 * Deserializes binary data (in protobuf wire format) from the
 * given reader into the given message object.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} msg The message object to deserialize into.
 * @param {!jspb.BinaryReader} reader The BinaryReader to use.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.deserializeBinaryFromReader=function(msg,reader){while(reader.nextField()){if(reader.isEndGroup()){break;}var field=reader.getFieldNumber();switch(field){case 1:var value=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */reader.readEnum();msg.setPayloadtype(value);break;case 2:var value=/** @type {number} */reader.readUint32();msg.setFreetrades(value);break;case 3:var value=/** @type {boolean} */reader.readBool();msg.setInfinitytrades(value);break;case 4:var value=/** @type {number} */reader.readUint64();msg.setUntilutctimestamp(value);break;case 5:var value=/** @type {boolean} */reader.readBool();msg.setInfinitytimestamp(value);break;default:reader.skipField();break;}}return msg;};/**
 * Serializes the message to binary data (in protobuf wire format).
 * @return {!Uint8Array}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.serializeBinary=function(){var writer=new jspb.BinaryWriter();proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.serializeBinaryToWriter(this,writer);return writer.getResultBuffer();};/**
 * Serializes the given message to binary data (in protobuf wire
 * format), writing to the given BinaryWriter.
 * @param {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} message
 * @param {!jspb.BinaryWriter} writer
 * @suppress {unusedLocalVariables} f is only used for nested messages
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.serializeBinaryToWriter=function(message,writer){var f=undefined;f=/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getField(message,1);if(f!=null){writer.writeEnum(1,f);}f=/** @type {number} */jspb.Message.getField(message,2);if(f!=null){writer.writeUint32(2,f);}f=/** @type {boolean} */jspb.Message.getField(message,3);if(f!=null){writer.writeBool(3,f);}f=/** @type {number} */jspb.Message.getField(message,4);if(f!=null){writer.writeUint64(4,f);}f=/** @type {boolean} */jspb.Message.getField(message,5);if(f!=null){writer.writeBool(5,f);}};/**
 * optional ProtoQXPayloadType payloadType = 1;
 * @return {!proto.SBProtoMessages.ProtoQXPayloadType}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.getPayloadtype=function(){return(/** @type {!proto.SBProtoMessages.ProtoQXPayloadType} */jspb.Message.getFieldWithDefault(this,1,2102));};/**
 * @param {!proto.SBProtoMessages.ProtoQXPayloadType} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.setPayloadtype=function(value){return jspb.Message.setField(this,1,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.clearPayloadtype=function(){return jspb.Message.setField(this,1,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.hasPayloadtype=function(){return jspb.Message.getField(this,1)!=null;};/**
 * optional uint32 FreeTrades = 2;
 * @return {number}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.getFreetrades=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,2,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.setFreetrades=function(value){return jspb.Message.setField(this,2,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.clearFreetrades=function(){return jspb.Message.setField(this,2,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.hasFreetrades=function(){return jspb.Message.getField(this,2)!=null;};/**
 * optional bool InfinityTrades = 3;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.getInfinitytrades=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,3,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.setInfinitytrades=function(value){return jspb.Message.setField(this,3,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.clearInfinitytrades=function(){return jspb.Message.setField(this,3,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.hasInfinitytrades=function(){return jspb.Message.getField(this,3)!=null;};/**
 * optional uint64 UntilUtcTimestamp = 4;
 * @return {number}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.getUntilutctimestamp=function(){return(/** @type {number} */jspb.Message.getFieldWithDefault(this,4,0));};/**
 * @param {number} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.setUntilutctimestamp=function(value){return jspb.Message.setField(this,4,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.clearUntilutctimestamp=function(){return jspb.Message.setField(this,4,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.hasUntilutctimestamp=function(){return jspb.Message.getField(this,4)!=null;};/**
 * optional bool InfinityTimestamp = 5;
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.getInfinitytimestamp=function(){return(/** @type {boolean} */jspb.Message.getBooleanFieldWithDefault(this,5,false));};/**
 * @param {boolean} value
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.setInfinitytimestamp=function(value){return jspb.Message.setField(this,5,value);};/**
 * Clears the field making it undefined.
 * @return {!proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent} returns this
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.clearInfinitytimestamp=function(){return jspb.Message.setField(this,5,undefined);};/**
 * Returns whether this field is set.
 * @return {boolean}
 */proto.SBProtoMessages.ProtoFreeTradesCounterChangedEvent.prototype.hasInfinitytimestamp=function(){return jspb.Message.getField(this,5)!=null;};goog.object.extend(exports,proto.SBProtoMessages);